import { useMemo } from "react";
import { Box, Table, Typography } from "@esgian/esgianui";
import moment from "moment";

import {
  calculateFilterCount,
  filterListByDatePicker,
  filterListByDropdown,
  filterListByNumericSlider,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
  getUser,
} from "../../store/selector/common";
import {
  getInstallationList,
  getIsLoading,
  getSelectedFilter,
  getSortingInfo,
  getVisibleColumns,
} from "../../store/selector/installation";
import {
  setRowsPerPage,
  setSelectedInstallationId,
} from "../../store/slice/common";
import {
  setSortingInfo,
  setVisibleColumns,
} from "../../store/slice/installation";
import { GenericType, IdNamePair, Installation } from "../../types";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";

export const InstallationList = () => {
  const dispatch = useDispatch();
  const theme = useSelector(getThemeMode);
  const isLoading = useSelector(getIsLoading);
  const searchText = useSelector(getSearchText);
  const rowsPerPage = useSelector(getRowsPerPage);
  const sortingInfo = useSelector(getSortingInfo);
  const installationList = useSelector(getInstallationList);
  const selectedFilter = useSelector(getSelectedFilter);
  const visibleColumns = useSelector(getVisibleColumns);
  const user = useSelector(getUser);

  const data = useMemo(() => {
    if (!installationList || !installationList?.length) return [];
    let list = installationList;
    if (searchText?.length && list?.length) {
      list = list?.filter((item: Installation) => isMatch(item, [searchText]));
    }
    if (searchText?.length && list?.length) {
      list = list?.filter((item: Installation) => isMatch(item, [searchText]));
    }

    // Apply selected filter if available
    if (
      selectedFilter &&
      list &&
      Object.keys(selectedFilter).some((key) => selectedFilter[key].length > 0)
    ) {
      list = filterListByDropdown(list, selectedFilter) as Installation[];
      list = filterListByDatePicker(list, selectedFilter) as Installation[];
      list = filterListByNumericSlider(list, selectedFilter) as Installation[];
    }
    return list;
  }, [installationList, searchText, selectedFilter]);

  const COLUMNS = [
    {
      label: "Vessel name",
      name: "vesselName",
      options: {
        display: visibleColumns.includes("vesselName"),
        setCellProps: () => ({ style: { cursor: "pointer" } }),
        customBodyRender: (value: IdNamePair) => value || "N/A",
      },
    },
    {
      label: "Owner",
      name: "owner",
      options: { display: visibleColumns.includes("owner") },
    },
    {
      label: "Design",
      name: "design",
      options: { display: visibleColumns.includes("design") },
    },
    {
      label: "Crane make",
      name: "craneMake",
      options: { display: visibleColumns.includes("craneMake") },
    },
    {
      label: "Crane maximum capacity (t)",
      name: "craneMaxCapacity",
      options: {
        display: visibleColumns.includes("craneMaxCapacity"),
        sortCompare: (direction: string) => {
          return (firstRow: GenericType, secondRow: GenericType) => {
            const firstNumber = Number(firstRow.data.split(",")[0]) || 0;
            const secondNumber = Number(secondRow.data.split(",")[0]) || 0;
            return direction === "asc"
              ? firstNumber - secondNumber
              : secondNumber - firstNumber;
          };
        },
      },
    },
    {
      label: "Maximum lift above deck (m)",
      name: "maxLiftAboveDeck",
      options: {
        display: visibleColumns.includes("maxLiftAboveDeck"),
        sortCompare: (direction: string) => {
          return (firstRow: GenericType, secondRow: GenericType) => {
            const firstNumber = Number(firstRow.data.split(",")[0]) || 0;
            const secondNumber = Number(secondRow.data.split(",")[0]) || 0;
            return direction === "asc"
              ? firstNumber - secondNumber
              : secondNumber - firstNumber;
          };
        },
      },
    },
    {
      label: "Deck area (m2)",
      name: "deckArea",
      options: { display: visibleColumns.includes("deckArea") },
    },
    {
      label: "Date of delivery",
      name: "dateOfDelivery",
      options: { display: visibleColumns.includes("dateOfDelivery") },
    },
    {
      label: "Newbuild",
      name: "newbuild",
      options: { display: visibleColumns.includes("newbuild") },
    },
    {
      label: "Vessel type",
      name: "vesselType",
      options: { display: visibleColumns.includes("vesselType") },
    },
    {
      label: "Status",
      name: "status",
      options: { display: visibleColumns.includes("status") },
    },
    {
      label: "Vessel capability",
      name: "vesselCapability",
      options: { display: visibleColumns.includes("vesselCapability") },
    },
    {
      label: "Class",
      name: "class",
      options: { display: visibleColumns.includes("class") },
    },
    {
      label: "IMO",
      name: "imo",
      options: { display: visibleColumns.includes("imo") },
    },
    {
      label: "Flag",
      name: "flag",
      options: { display: visibleColumns.includes("flag") },
    },
    {
      label: "Constructed at yard",
      name: "constructedYard",
      options: { display: visibleColumns.includes("constructedYard") },
    },
    {
      label: "MMSI",
      name: "mmsi",
      options: { display: visibleColumns.includes("mmsi") },
    },
    {
      label: "LOA (m)",
      name: "loa",
      options: { display: visibleColumns.includes("loa") },
    },
    {
      label: "Width (m)",
      name: "width",
      options: { display: visibleColumns.includes("width") },
    },
    {
      label: "Draught (m)",
      name: "draught",
      options: { display: visibleColumns.includes("draught") },
    },
    {
      label: "Future crane upgrade",
      name: "futureCraneUpgrade",
      options: { display: visibleColumns.includes("futureCraneUpgrade") },
    },
    {
      label: "Crane type",
      name: "craneType",
      options: { display: visibleColumns.includes("craneType") },
    },
    {
      label: "Crane mode",
      name: "craneModel",
      options: { display: visibleColumns.includes("craneModel") },
    },
    {
      label: "Hook height above deck (m)",
      name: "hookHeightAboveDeck",
      options: { display: visibleColumns.includes("hookHeightAboveDeck") },
    },
    {
      label: "Long boom weight (t)",
      name: "longBoomWeight",
      options: { display: visibleColumns.includes("longBoomWeight") },
    },
    {
      label: "Long boom radius (m)",
      name: "longBoomRadius",
      options: { display: visibleColumns.includes("longBoomRadius") },
    },
    {
      label: "Short boom weight (t)",
      name: "shortBoomWeight",
      options: { display: visibleColumns.includes("shortBoomWeight") },
    },
    {
      label: "Short boom radius (m)",
      name: "shortBoomRadius",
      options: { display: visibleColumns.includes("shortBoomRadius") },
    },
    {
      label: "Boom length (m)",
      name: "boomLength",
      options: { display: visibleColumns.includes("boomLength") },
    },
    {
      label: "Maximum deck strength (t/m2)",
      name: "maxDeckStrength",
      options: { display: visibleColumns.includes("maxDeckStrength") },
    },
    {
      label: "Maximum deck load capacity (t)",
      name: "maxDeckLoadCapacity",
      options: { display: visibleColumns.includes("maxDeckLoadCapacity") },
    },
    {
      label: "Maximum speed (kn)",
      name: "maxSpeed",
      options: { display: visibleColumns.includes("maxSpeed") },
    },
    {
      label: "Leg length (m)",
      name: "legLength",
      options: { display: visibleColumns.includes("legLength") },
    },
    {
      label: "Elevating / jacking speed (m/s)",
      name: "elevatingJackingSpeed",
      options: { display: visibleColumns.includes("elevatingJackingSpeed") },
    },
    {
      label: "Pre-load capacity (t)",
      name: "preLoadCapacity",
      options: { display: visibleColumns.includes("preLoadCapacity") },
    },
    {
      label: "Maximum jacking load (t)",
      name: "maxJackingLoad",
      options: { display: visibleColumns.includes("maxJackingLoad") },
    },
    {
      label: "Jacking system make",
      name: "jackingSystemMake",
      options: { display: visibleColumns.includes("jackingSystemMake") },
    },
    {
      label: "Jacking system model",
      name: "jackingSystemModel",
      options: { display: visibleColumns.includes("jackingSystemModel") },
    },
    {
      label: "Maximum POB",
      name: "maxPob",
      options: { display: visibleColumns.includes("maxPob") },
    },
    {
      label: "Minimum water depth (m)",
      name: "minWaterDepth",
      options: { display: visibleColumns.includes("minWaterDepth") },
    },
    {
      label: "Maximum water depth (m)",
      name: "maxWaterDepth",
      options: { display: visibleColumns.includes("maxWaterDepth") },
    },
  ];

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  return (
    <Box sx={{ px: 2, maxWidth: "calc(100vw - 265px)" }}>
      {!isLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} Installation vessels`}
            </Typography>
            <FilterBtn count={calculateFilterCount(selectedFilter)} />
          </Box>
          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={data}
            elevation={0}
            mode={theme}
            options={{
              download: hasExportRight,
              search: false,
              downloadOptions: {
                filename: `installation_data.csv`,
              },
              onDownload: (
                buildHead: (columns: object[]) => string[],
                buildBody: (data: object) => string,
                propColumns: GenericType[],
                propData: GenericType[],
              ) => {
                const tableData = [...propData].map((tableRow) => {
                  const res: string[] = [];
                  Object.keys(tableRow.data).forEach((key) => {
                    const val = tableRow.data[key];
                    if (
                      moment(val, "DD-MM-YYYY").isValid() &&
                      val !== undefined &&
                      val.length === 10 &&
                      val.includes("-")
                    ) {
                      res[Number(key)] = moment(val, "DD-MM-YYYY").format(
                        "YYYY/MM/DD",
                      );
                    } else {
                      res[Number(key)] = val;
                    }
                  });
                  return { data: res, index: undefined };
                });
                return "\uFEFF" + buildHead(propColumns) + buildBody(tableData);
              },
              expandableRows: false,
              filter: false,
              filterType: "multiselect",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "vertical",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              onRowClick: (_: number, rowMeta: GenericType) => {
                const { dataIndex }: GenericType = rowMeta;
                const item: GenericType = data[dataIndex];
                dispatch(setSelectedInstallationId(item?.id || null));
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",
              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setSortingInfo({ name, direction }));
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 310px)",
              toolbar: true,
              print: false,
              viewColumns: true,
              onViewColumnsChange: (c: string | string[]) => {
                dispatch(setVisibleColumns(c));
              },
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
