import { FC, SetStateAction, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  HelpOutlineIcon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  ToolTip,
  Typography,
} from "@esgian/esgianui";

import { api } from "../../../api/api";
import { useDispatch } from "../../../hooks/use-dispatch";
import { useSelector } from "../../../hooks/use-selector";
import { getEnquirySent } from "../../../store/selector/common";
import { setEnquirySent } from "../../../store/slice/common";

const positions = [
  "Administration",
  "Analyst",
  "Business Development",
  "Commercial",
  "Market Intelligence",
  "Marketing",
  "Procurement",
  "QHSE",
  "Sales",
  "Senior Management",
  "Tech. Department",
];

export const EnquiryModal: FC = () => {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    contact: "",
    comments: "",
    termsAccepted: "",
  });

  const enquirySent = useSelector(getEnquirySent);

  const validate = () => {
    let valid = true;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const errors = {
      fullName: "",
      email: "",
      contact: "",
      comments: "",
      termsAccepted: "",
    };

    // Full name validation (required)
    const trimmedName = fullName.trim();
    if (!trimmedName) {
      errors.fullName = "Full name is required.";
      valid = false;
    } else {
      let hasLetter = false;
      for (let i = 0; i < trimmedName.length; i++) {
        const char = trimmedName[i];
        if ((char >= "a" && char <= "z") || (char >= "A" && char <= "Z")) {
          hasLetter = true;
          break;
        }
      }
      if (!hasLetter) {
        errors.fullName = "Full name must include letters.";
        valid = false;
      }
    }

    if (!email.trim()) {
      errors.email = "Email is required.";
      valid = false;
    } else if (!email.includes("@")) {
      errors.email = "Email must include '@'.";
      valid = false;
    } else if (!email.includes(".")) {
      errors.email = "Email must include a domain (like '.com').";
      valid = false;
    }

    // Contact validation (optional but validated if provided)
    const validPhoneChars = "0123456789 +-()";
    if (contact) {
      const isPhoneValid = [...contact].every((char) =>
        validPhoneChars.includes(char),
      );
      const minLength = contact.replace(/[^0-9]/g, "").length >= 7; // At least 7 digits

      if (!isPhoneValid || !minLength) {
        errors.contact = "Invalid phone number format.";
        valid = false;
      }
    }

    // Enquiry Message validation (required)
    if (!comments.trim()) {
      errors.comments = "Enquiry message is required.";
      valid = false;
    }

    // Terms acceptance validation (required)
    if (!termsAccepted) {
      errors.termsAccepted = "You must accept the terms.";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = async () => {
    if (validate()) {
      try {
        await api.notification.sendEmail({
          to: process.env.REACT_APP_ENQUIRY_TO_EMAIL,
          from: process.env.REACT_APP_ENQUIRY_FROM_EMAIL,
          subject: "Wind Analytics Enquiry",
          body: `
            Full Name: ${fullName}
            Email: ${email}
            Contact: ${contact}
            Position: ${position}
            Message: ${comments}
          `,
          html: false,
        });
        dispatch(setEnquirySent(true));
        setFullName("");
        setEmail("");
        setContact("");
        setPosition("");
        setComments("");
        setTermsAccepted(false);
        setErrors({
          fullName: "",
          email: "",
          contact: "",
          comments: "",
          termsAccepted: "",
        });
      } catch (err) {
        console.log(">>>", err);
      }
    }
  };

  const sendOtherEnquiry = () => {
    dispatch(setEnquirySent(false));
  };

  return (
    <Modal
      open={true}
      size="sm"
      hideBackdrop={true}
      title={
        !enquirySent ? (
          <Box>
            <Typography variant="h5">Enquiry</Typography>
            <Typography variant="subtitle1">
              Complete the form for more information about Esgian’s Wind
              Analytics digital platform
            </Typography>
          </Box>
        ) : null
      }
      disableEnforceFocus={true}
      sx={{
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
        width: 700,
        pointerEvents: "auto",
        zIndex: 1300,
      }}
    >
      {!enquirySent ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Full name *"
            variant="outlined"
            value={fullName}
            onChange={(e: { target: { value: SetStateAction<string> } }) =>
              setFullName(e.target.value)
            }
            error={!!errors.fullName}
            helperText={errors.fullName}
            type="text"
          />
          <TextField
            label="E-mail *"
            variant="outlined"
            value={email}
            onChange={(e: { target: { value: SetStateAction<string> } }) =>
              setEmail(e.target.value)
            }
            error={!!errors.email}
            helperText={errors.email}
            type="email"
          />
          <TextField
            label="Contact - Direct phone"
            variant="outlined"
            value={contact}
            onChange={(e: { target: { value: SetStateAction<string> } }) =>
              setContact(e.target.value)
            }
            error={!!errors.contact}
            helperText={errors.contact}
            inputMode="numeric"
            type="tel"
            sx={{
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                {
                  WebkitAppearance: "none",
                  margin: 0,
                },
            }}
          />
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel labelId="label-id">Position</InputLabel>
            <Select
              labelId="label-id"
              value={position}
              onChange={(e: { target: { value: SetStateAction<string> } }) =>
                setPosition(e.target.value)
              }
              label="Position"
            >
              {positions.map((p) => (
                <MenuItem key={p} value={p}>
                  {p}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Enquiry - Message *"
            variant="outlined"
            value={comments}
            onChange={(e: { target: { value: SetStateAction<string> } }) =>
              setComments(e.target.value)
            }
            multiline={true}
            rows={4}
            error={!!errors.comments}
            helperText={errors.comments}
            type="text"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e: { target: { checked: boolean } }) =>
                  setTermsAccepted(e.target.checked)
                }
              />
            }
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2">I accept these terms *</Typography>
                <ToolTip
                  placement="right"
                  title="To be able to serve you the best possible way, we need to store some basic data about you. This includes your name, e-mail address, company information, role and phone number. We will not share your data with anyone without your explicit consent. The data will be stored in a secure manner. You may change or withdraw your consent at any time, by contacting our customer service."
                >
                  <HelpOutlineIcon
                    sx={{
                      height: 16,
                      width: 16,
                      ml: 1,
                    }}
                  />
                </ToolTip>
              </Box>
            }
            sx={{ alignItems: "center" }}
          />
          {errors.termsAccepted && (
            <Typography color="error" variant="caption">
              {errors.termsAccepted}
            </Typography>
          )}
          <Button onClick={handleSubmit} sx={{ mt: 2 }}>
            Submit
          </Button>
        </Box>
      ) : (
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" gutterBottom={true}>
            Thanks for reaching out! We will get back to you very shortly
          </Typography>
          <Typography variant="body1" gutterBottom={true}>
            Working hours are CET (Central European Time)
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={sendOtherEnquiry} sx={{ mt: 4 }}>
              Send another enquiry
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
};
