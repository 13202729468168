import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import {
  DATE_LABELS,
  DateConfig,
  DROPDOWN_LABELS,
  DropdownConfig,
  isDropdownConfig,
  isDropdownConfigWithType,
  isNumericConfig,
  NUMERIC_LABELS,
  NumericConfig,
} from "../../types/filters";
import { RegionType } from "../../types/region";

export const getPolygons = (state: RootState) => {
  return state.windLeasingAndProject.polygons;
};

export const getInfoModal = (state: RootState) => {
  return state.windLeasingAndProject.isInfoModalOpen;
};

export const getLeaseRegionLookup = (state: RootState) => {
  return state.windLeasingAndProject.leaseRegionLookup;
};

export const getBlurred = (state: RootState) => {
  return state.windLeasingAndProject.blurred;
};

export const getCountryListInfo = (state: RootState) => {
  return state.windLeasingAndProject.countryListInfo;
};

export const getLeaseListInfo = (state: RootState) => {
  return state.windLeasingAndProject.leaseListInfo;
};

export const getLeaseRoundListInfo = (state: RootState) => {
  return state.windLeasingAndProject.leaseRoundListInfo;
};

export const getProjectListInfoListInfo = (state: RootState) => {
  return state.windLeasingAndProject.projectListInfo;
};

export const getListViewIsLoading = (state: RootState) => {
  return state.windLeasingAndProject.isLoading;
};

export const getListViewSelectedTab = (state: RootState) => {
  return state.windLeasingAndProject.listViewSelectedTab;
};

export const getSelectedFilter = (state: RootState) => {
  return state.windLeasingAndProject.selectedFilter;
};

export const getMapActiveLegends = (state: RootState) => {
  return state.windLeasingAndProject.mapActiveLegends;
};

export const getSortingInfo = (state: RootState) => {
  return state.windLeasingAndProject.sortingInfo;
};

export const getVisibleColumns = (state: RootState) => {
  return state.windLeasingAndProject.visibleColumns;
};

export const getRegionNames = (state: RootState) => {
  return state.windLeasingAndProject.regionNames;
};

export const getFilterOptionsMap = (state: RootState) => {
  const regionNames = [
    ...new Map(
      (state?.windLeasingAndProject?.polygons ?? [])
        .filter((p) => p.name != null && p.name !== "")
        .map((p) => [
          p.name,
          {
            regionName: p.name,
            regionTypeId: p.regionTypeId,
            regionId: p.regionId,
          },
        ]),
    ).values(),
  ];

  if (!regionNames || regionNames.length === 0) {
    return null; // If no regions found, return early
  }

  // Deriving a new array with the specified properties
  const uniqueRegions = regionNames?.map((item) => ({
    id: item.regionId,
    value: item.regionName,
    type: RegionType[item.regionTypeId as number] ?? RegionType.unknown,
  }));

  const uniqueCommercialRegionsShips = sortValues(
    uniqueRegions.filter(
      (region) => region.type === RegionType[RegionType.commercialRegionsShips],
    ),
  );

  const uniqueWeatherRegionsShips = sortValues(
    uniqueRegions.filter(
      (region) => region.type === RegionType[RegionType.weatherRegionsShips],
    ),
  );

  const uniqueLeaseAreas = sortValues(
    uniqueRegions.filter((region) => region.type.toString() === "leaseArea"),
  );

  const uniqueWindProjectAreas = sortValues(
    uniqueRegions.filter(
      (region) => region.type.toString() === "windProjectArea",
    ),
  );

  const uniqueSeaAreas = sortValues(
    uniqueRegions.filter((region) => region.type.toString() === "seaArea"),
  );

  const uniqueContinents = sortValues(
    uniqueRegions.filter((region) => region.type.toString() === "continent"),
  );

  const uniquePoliticalRegions = sortValues(
    uniqueRegions.filter((region) => region.type.toString() === "political"),
  );

  const uniqueCountries = sortValues(
    uniqueRegions.filter((region) => region.type.toString() === "country"),
  );

  const uniqueTerminals = sortValues(
    uniqueRegions.filter((region) => region.type.toString() === "terminal"),
  );

  const uniqueAnchorageSites = sortValues(
    uniqueRegions.filter(
      (region) => region.type.toString() === "anchorageSite",
    ),
  );

  const uniqueCanals = sortValues(
    uniqueRegions.filter((region) => region.type.toString() === "canal"),
  );

  const uniqueUnknown = sortValues(
    uniqueRegions.filter((region) => region.type.toString() === "unknown"),
  );

  const filters = {
    name: {
      inputLabelText: "Location",
      options: [
        ...uniqueCommercialRegionsShips,
        ...uniqueWeatherRegionsShips,
        ...uniqueLeaseAreas,
        ...uniqueWindProjectAreas,
        ...uniqueSeaAreas,
        ...uniqueContinents,
        ...uniquePoliticalRegions,
        ...uniqueCountries,
        ...uniqueTerminals,
        ...uniqueAnchorageSites,
        ...uniqueCanals,
        ...uniqueUnknown,
      ],
      groupBy: "type",
    },
    leaseRoundStatus: {
      inputLabelText: "Lease round status",
      options: sortValues([
        ...new Set(
          Object.values(state.common.leaseRoundStatus ?? {})
            .map((item) => ({
              id: item.id,
              value: item.status,
            }))
            .filter(
              (item) =>
                item.value != null &&
                item.value !== "" &&
                item.value !== undefined,
            ),
        ),
      ]).map((item: GenericType) => ({ id: item.id, value: item.value })),
    },
    leaseRoundType: {
      inputLabelText: "Lease area type",
      options: sortValues([
        ...new Set(
          Object.values(state.common.leaseRoundType ?? {})
            .map((item) => ({
              id: item.id,
              value: item.type,
            }))
            .filter(
              (item) =>
                item.value != null &&
                item.value !== "" &&
                item.value !== undefined,
            ),
        ),
      ]).map((item: GenericType) => ({ id: item.id, value: item.value })),
    },
    projectStatus: {
      inputLabelText: "Project status",
      options: sortValues([
        ...new Set(
          Object.values(state.common.projectStatus ?? {})
            .map((item) => ({
              id: item.id,
              value: item.status,
            }))
            .filter(
              (item) =>
                item.value != null &&
                item.value !== "" &&
                item.value !== undefined,
            ),
        ),
      ]).map((item: GenericType) => ({ id: item.id, value: item.value })),
    },
    projectType: {
      inputLabelText: "Project type",
      options: sortValues([
        ...new Set(
          Object.values(state.common.projectType ?? {})
            .map((item) => ({
              id: item.id,
              value: item.type,
            }))
            .filter(
              (item) =>
                item.value != null &&
                item.value !== "" &&
                item.value !== undefined,
            ),
        ),
      ]).map((item: GenericType) => ({ id: item.id, value: item.value })),
    },
    developers: {
      inputLabelText: "Company",
      options: sortValues([
        ...new Set(
          Object.values(state.common.company ?? {})
            .filter((item) => item.windProjects.length > 0)
            .map((item) => ({
              id: item.company.id,
              value: item.company.companyName,
            }))
            .filter(
              (item) =>
                item.value != null &&
                item.value !== "" &&
                item.value !== undefined,
            ),
        ),
      ]).map((item: GenericType) => ({ id: item.id, value: item.value })),
    },

    operationDate: {
      inputLabelText: "Operation period",
      format: "YYYY MM",
    },
    awardDate: {
      inputLabelText: "Award period",
      format: "YYYY MM",
    },
  };

  if (
    Object.values(filters).some((filter) => {
      if ("options" in filter) {
        return filter.options.length === 0; // Check if options Set is empty
      } else if ("format" in filter) {
        return !filter.format; // Check if format is missing
      }
      return false; // Default return if neither options nor format is present
    })
  ) {
    return null;
  }

  const result: GenericType = {};
  Object.entries(filters).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfigWithType(options)
      ) {
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          groupBy: "type",
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
            type: option.type,
          })),
        });
      } else if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      }
    } else if ("format" in filter) {
      if (DATE_LABELS.includes(filter.inputLabelText)) {
        const dateFilter = filter as DateConfig;
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            dateFilter.inputLabelText as DateConfig["inputLabelText"],
          format: dateFilter.format,
        });
      }
    }
  });

  return result;
};

export const getFilterOptionsProject = (state: RootState) => {
  if (!state.windLeasingAndProject?.projectListInfo) return null;

  const filtersKeyInfo = {
    project: {
      inputLabelText: "Project",
      options: sortValues([
        ...new Set(
          state.windLeasingAndProject.projectListInfo
            .map((item) => item.project)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    country: {
      inputLabelText: "Country",
      options: sortValues([
        ...new Set(
          state.windLeasingAndProject.projectListInfo
            .map((item) => item.country)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    developers: {
      inputLabelText: "Developers",
      options: sortValues([
        ...new Set(
          state.windLeasingAndProject.projectListInfo
            .map((item) => item.developers)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    projectStatus: {
      inputLabelText: "Project status",
      options: sortValues([
        ...new Set(
          state.windLeasingAndProject.projectListInfo
            .map((item) => item.projectStatus)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    projectType: {
      inputLabelText: "Project type",
      options: sortValues([
        ...new Set(
          state.windLeasingAndProject.projectListInfo
            .map((item) => item.projectType)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    capacity: {
      inputLabelText: "Capacity",
      options: [
        0,
        Math.max(
          ...state.windLeasingAndProject.projectListInfo
            .map((item) => item.capacity)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    numberOfTurbines: {
      inputLabelText: "Number of turbines",
      options: [
        0,
        Math.max(
          ...state.windLeasingAndProject.projectListInfo
            .map((item) => item.numberOfTurbines)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    operationDate: {
      inputLabelText: "Operation period",
      format: "YYYY MM",
    },
    decommissioningDate: {
      inputLabelText: "Decommissioning date",
      format: "YYYY MM",
    },
    leaseRound: {
      inputLabelText: "Lease round",
      options: sortValues([
        ...new Set(
          state.windLeasingAndProject.projectListInfo
            .map((item) => item.leaseRound)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    seaRegion: {
      inputLabelText: "Sea region",
      options: sortValues([
        ...new Set(
          state.windLeasingAndProject.projectListInfo
            .map((item) => item.seaRegion)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    maximumWaterDepth: {
      inputLabelText: "Maximum water depth",
      options: [
        0,
        Math.max(
          ...state.windLeasingAndProject.projectListInfo
            .map((item) => item.maximumWaterDepth)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
  };

  const filtersEquipmentInfo = {
    turbineSupplier: {
      inputLabelText: "Turbine supplier",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.turbineSupplier)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    turbineModel: {
      inputLabelText: "Turbine model",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.turbineModel)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    foundationSupplier: {
      inputLabelText: "Foundation supplier",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.foundationSupplier)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    foundationType: {
      inputLabelText: "Foundation type",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.foundationType)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
  };

  const filtersVesselInfo = {
    mainPortName: {
      inputLabelText: "Main port name",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.mainPortName)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    turbineInstaller: {
      inputLabelText: "Turbine installer",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.turbineInstaller)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    cableInstaller: {
      inputLabelText: "Cable installer",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.cableInstaller)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    foundationInstaller: {
      inputLabelText: "Foundation installer",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.foundationInstaller)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
  };

  const filtersFloatingInfo = {
    floatingMaterial: {
      inputLabelText: "Floating material",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.floatingMaterial)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    floatingTechnology: {
      inputLabelText: "Floating technology",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.floatingTechnology)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    floaterType: {
      inputLabelText: "Floater type",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.floaterType)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    mooringLineType: {
      inputLabelText: "Mooring line type",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.mooringLineType)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
  };

  const filtersHydrogenInfo = {
    hydrogen: {
      inputLabelText: "Hydrogen",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.hydrogen)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    hydrogenProject: {
      inputLabelText: "Hydrogen project",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.hydrogenProject)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    hydrogenDeveloper: {
      inputLabelText: "Hydrogen developer",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.hydrogenDeveloper)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    hydrogenTechnology: {
      inputLabelText: "Hydrogen technology",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.hydrogenTechnology)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    dateOnline: {
      inputLabelText: "Date online",
      format: "YYYY MM",
    },
  };

  const filtersOilGasInfo = {
    oilAndGas: {
      inputLabelText: "Oil and gas",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.oilAndGas)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    oilGasFields: {
      inputLabelText: "Oil and gas fields",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.oilGasFields)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
  };

  if (
    Object.values(filtersKeyInfo).some((filter) => {
      if ("options" in filter) {
        return filter.options.length === 0; // Check if options Set is empty
      } else if ("format" in filter) {
        return !filter.format; // Check if format is missing
      }
      return false; // Default return if neither options nor format is present
    })
  ) {
    return null;
  }
  if (
    Object.values(filtersEquipmentInfo).some(
      (filter) => !filter.options?.length,
    )
  ) {
    return null;
  }
  if (
    Object.values(filtersVesselInfo).some((filter) => !filter.options?.length)
  ) {
    return null;
  }
  if (
    Object.values(filtersFloatingInfo).some((filter) => !filter.options?.length)
  ) {
    return null;
  }
  if (
    Object.values(filtersHydrogenInfo).some((filter) => {
      if ("options" in filter) {
        return filter.options.length === 0; // Check if options Set is empty
      } else if ("format" in filter) {
        return !filter.format; // Check if format is missing
      }
      return false; // Default return if neither options nor format is present
    })
  ) {
    return null;
  }
  if (
    Object.values(filtersOilGasInfo).some((filter) => !filter.options?.length)
  ) {
    return null;
  }

  const resultKeyInfo: GenericType = {};
  const resultEquipmentInfo: GenericType = {};
  const resultVesselInfo: GenericType = {};
  const resultFloatingInfo: GenericType = {};
  const resultHydrogenInfo: GenericType = {};
  const resultOilGasInfo: GenericType = {};

  Object.entries(filtersKeyInfo).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultKeyInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultKeyInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filtersEquipmentInfo).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultEquipmentInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultEquipmentInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filtersVesselInfo).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultVesselInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultVesselInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filtersFloatingInfo).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultFloatingInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultFloatingInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filtersHydrogenInfo).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultHydrogenInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultHydrogenInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filtersOilGasInfo).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultOilGasInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultOilGasInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  const groupedFilters = {
    keyInfo: resultKeyInfo,
    equipment: resultEquipmentInfo,
    vessels: resultVesselInfo,
    floating: resultFloatingInfo,
    hydrogen: resultHydrogenInfo,
    oilAndGas: resultOilGasInfo,
  };
  return groupedFilters;
};

export const getFilterOptionsCountryLease = (state: RootState) => {
  if (!state.windLeasingAndProject?.projectListInfo) return null;

  const filters = {
    countryName: {
      inputLabelText: "Country",
      options: sortValues([
        ...new Set(
          (state.windLeasingAndProject.countryListInfo ?? [])
            .map((item) => item.countryName)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            ) || [],
        ),
      ]).map((value: string) => ({
        id: value,
        value,
      })),
    },
    leaseRoundStatus: {
      inputLabelText: "Lease round status",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.leaseRoundListInfo ?? {})
            .map((item) => item.leaseRoundStatus)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    leaseRoundType: {
      inputLabelText: "Lease area type",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.leaseRoundListInfo ?? {})
            .map((item) => item.leaseRoundType)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    projectStatus: {
      inputLabelText: "Project status",
      options: sortValues([
        ...new Set(
          Object.values(state.windLeasingAndProject.projectListInfo ?? {})
            .map((item) => item.projectStatus)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    projectType: {
      inputLabelText: "Project type",
      options: sortValues([
        ...new Set(
          state.windLeasingAndProject.projectListInfo
            .map((item) => item.projectType)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
  };

  if (Object.values(filters).some((filter) => !filter.options?.length)) {
    return null;
  }

  const result: GenericType = {};
  Object.entries(filters).forEach(([key, filter]) => {
    if (
      DROPDOWN_LABELS.includes(filter.inputLabelText) &&
      "options" in filter
    ) {
      const dropdownFilter = filter as DropdownConfig;
      result[key] = FilterFactory.createFilter({
        inputLabelText:
          dropdownFilter.inputLabelText as DropdownConfig["inputLabelText"],
        options: sortValues(dropdownFilter.options),
      });
    } else if (
      DATE_LABELS.includes(filter.inputLabelText) &&
      "format" in filter
    ) {
      const dateFilter = filter as DateConfig;
      result[key] = FilterFactory.createFilter({
        inputLabelText:
          dateFilter.inputLabelText as DateConfig["inputLabelText"],
        format: dateFilter.format,
      });
    } else {
      result[key] = null;
    }
  });

  return result;
};
