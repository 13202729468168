import { useEffect, useState } from "react";
import { Box } from "@esgian/esgianui";

import { Loading } from "../../components/Loading";
import { FilterModal } from "../../components/Modal/FilterModal";
import { PowerOutputProjectListWindFarm } from "../../components/PowerOutputProjectListWindFarm";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import { getFilterModal } from "../../store/selector/common";
import {
  getFilterOptions,
  getSelectedFilter,
} from "../../store/selector/powerOutput";
import { setFilterModal } from "../../store/slice/common";
import {
  setMainPageList,
  setSelectedFilter,
} from "../../store/slice/powerOutput";
import { GenericType, PowerOutputEnum } from "../../types";

export const PowerOutput = () => {
  const { powerDataQuery } = usePowerGenQueries();
  const [selectedView, setSelectedView] = useState("Capacity");
  const options = useSelector(getFilterOptions);
  const isFilterModalOpen = useSelector(getFilterModal);
  const selectedFilter = useSelector(getSelectedFilter);
  const dispatch = useDispatch();

  useEffect(() => {
    if (powerDataQuery.isFetched && powerDataQuery.data) {
      dispatch(setMainPageList(powerDataQuery.data)); // No transformation needed
    }
  }, [powerDataQuery.isFetched, powerDataQuery.data, dispatch]);

  return (
    <Box>
      {options ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          onChange={(filterValues) => {
            dispatch(setSelectedFilter(filterValues));
          }}
          selectedFilter={selectedFilter}
          options={options}
        />
      ) : null}
      {!powerDataQuery.isFetching ? (
        <PowerOutputProjectListWindFarm
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          tab={PowerOutputEnum.WindFarm}
          tableData={powerDataQuery.data as GenericType}
        />
      ) : (
        <Loading />
      )}
    </Box>
  );
};
