import { FC, useMemo } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@esgian/esgianui";

import {
  filterListByDatePicker,
  filterListByDropdown,
  filterListByNumericSlider,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useSelector } from "../../hooks/use-selector";
import { getSearchText } from "../../store/selector/common";
import {
  getFloatingTechnologyChartData,
  getFloatingTechnologyList,
  getFloatingTechnologyLoading,
  getSelectedFilter,
} from "../../store/selector/floatingTechnology";
import {
  FloatingTechnology,
  FloatingTechnologyBarChart,
  FloatingTechnologyChartTabEnum,
  GenericType,
} from "../../types";
import { BarChart } from "../BarChart";

type Prop = {
  tab: FloatingTechnologyChartTabEnum;
  setTab: (e: FloatingTechnologyChartTabEnum) => void;
};

export const FloatingTechnologyChart: FC<Prop> = ({ tab, setTab }) => {
  const loading = useSelector(getFloatingTechnologyLoading);
  const chartData = useSelector(getFloatingTechnologyChartData);
  const searchText = useSelector(getSearchText);
  const selectedFilter = useSelector(getSelectedFilter);
  const floatingTechnologyList = useSelector(getFloatingTechnologyList);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTab(event.target.value as FloatingTechnologyChartTabEnum);
  };

  const data: GenericType | null = useMemo(() => {
    let list: FloatingTechnologyBarChart | null = {
      capacity: [],
      project: [],
      turbine: [],
    };
    let listDetailed = floatingTechnologyList;
    if (
      tab === FloatingTechnologyChartTabEnum.MarketAdaption &&
      chartData?.adaption
    ) {
      list = chartData.adaption;
    }
    if (
      tab === FloatingTechnologyChartTabEnum.MarketShare &&
      chartData?.share
    ) {
      list = chartData.share;
    }
    if (!list) return null;
    // Apply search if available
    if (searchText?.length && list) {
      const filteredList: FloatingTechnologyBarChart | null = {
        capacity: [],
        project: [],
        turbine: [],
      };
      // Loop through each key and its values
      Object.keys(list).forEach((key) => {
        // Type assertion to let TypeScript know key is a valid key in FloatingTechnologyBarChart
        const typedKey = key as keyof FloatingTechnologyBarChart;

        // Filter the array of items for the current key
        const filteredArray = list?.[typedKey].filter((item) =>
          item.some((innerItem) => {
            const valueToCheck =
              typeof innerItem === "number" ? innerItem.toString() : innerItem;
            return isMatch(valueToCheck, [searchText]);
          }),
        );

        // If there are any filtered items, add them to the new object
        if (filteredArray?.length) {
          filteredList[typedKey] = filteredArray;
        }
      });
      list = filteredList;
    }

    // Apply selected filter if available
    if (
      selectedFilter &&
      listDetailed &&
      Object.keys(selectedFilter).some((key) => selectedFilter[key].length > 0)
    ) {
      listDetailed = filterListByDropdown(
        listDetailed,
        selectedFilter,
      ) as FloatingTechnology[];
      listDetailed = filterListByDatePicker(
        listDetailed,
        selectedFilter,
      ) as FloatingTechnology[];
      listDetailed = filterListByNumericSlider(
        listDetailed,
        selectedFilter,
      ) as FloatingTechnology[];
    }

    const listDetailedIds = new Set(listDetailed?.map((item) => item.id));

    // Filter list based on ids in listDetailed
    const filteredListById: FloatingTechnologyBarChart = {
      capacity: [],
      project: [],
      turbine: [],
    };
    Object.keys(list).forEach((key) => {
      const typedKey = key as keyof FloatingTechnologyBarChart;

      // Filter each subarray based on whether its last element matches an id in listDetailed
      if (list) {
        filteredListById[typedKey] = list[typedKey]?.filter((subArray) => {
          const lastElement = subArray[subArray.length - 1];

          // Ensure the last element is a number before checking for inclusion in listDetailedIds
          return (
            typeof lastElement === "number" && listDetailedIds.has(lastElement)
          );
        });
      }
    });

    return Object.keys(filteredListById).length > 0 ? filteredListById : null;
  }, [chartData, tab, searchText, selectedFilter, floatingTechnologyList]);

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl component="fieldset" sx={{ px: 2 }} fullWidth={true}>
          <RadioGroup
            row={true}
            aria-labelledby="view-radio-buttons"
            name="view-radio-buttons"
            value={tab}
            onChange={handleChange}
          >
            <FormControlLabel
              value={FloatingTechnologyChartTabEnum.MarketAdaption}
              control={<Radio />}
              label="Market adaption"
            />
            <FormControlLabel
              value={FloatingTechnologyChartTabEnum.MarketShare}
              control={<Radio />}
              label="Market share"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ height: "calc(100vh - 115px)", overflowY: "auto", px: 1 }}>
        {tab === FloatingTechnologyChartTabEnum.MarketAdaption ? (
          <Grid container={true} direction="column" spacing={0}>
            <Grid item={true} xs={12} sm={4}>
              <BarChart
                type="horizontal"
                data={data?.capacity || []}
                title="MW capacity"
                loading={loading}
                height={1050}
                exportName="Market adoption, MW capacity"
              />
              <Divider sx={{ my: 2 }} />
            </Grid>
            <Grid item={true} xs={12} sm={4}>
              <BarChart
                type="horizontal"
                data={data?.turbine || []}
                title="Number of turbines"
                loading={loading}
                height={1050}
                exportName="Market adoption, Number of turbines"
              />
              <Divider sx={{ my: 2 }} />
            </Grid>
            <Grid item={true} xs={12} sm={4}>
              <BarChart
                type="horizontal"
                data={data?.project || []}
                title="Number of projects"
                loading={loading}
                height={1050}
                exportName="Market adoption, Number of projects"
              />
            </Grid>
          </Grid>
        ) : (
          <Box>
            <Grid container={true} direction="column" spacing={0}>
              <Grid item={true} xs={12} sm={4}>
                <BarChart
                  type="vertical"
                  data={data?.capacity || []}
                  title="MW"
                  multiSeries={true}
                  loading={loading}
                  exportName="Market share, MW capacity"
                />
                <Divider sx={{ my: 2 }} />
              </Grid>
              <Grid item={true} xs={12} sm={4}>
                <BarChart
                  type="vertical"
                  data={data?.turbine || []}
                  title="Number of turbines"
                  multiSeries={true}
                  loading={loading}
                  exportName="Market share, Number of turbines"
                />
                <Divider sx={{ my: 2 }} />
              </Grid>
              <Grid item={true} xs={12} sm={4}>
                <BarChart
                  type="vertical"
                  data={data?.project || []}
                  title="Number of projects"
                  multiSeries={true}
                  loading={loading}
                  exportName="Market share, Number of projects"
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};
