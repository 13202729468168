import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import {
  DROPDOWN_LABELS,
  DropdownConfig,
  isDropdownConfig,
  isNumericConfig,
  NUMERIC_LABELS,
  NumericConfig,
} from "../../types/filters";

export const getCableLayList = (state: RootState) => {
  return state.cableLay.cableLayList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.cableLay.selectedFilter;
};

export const getCableLayFilterOptions = (state: RootState) => {
  return state.cableLay.filterOptions;
};

export const getIsLoading = (state: RootState) => {
  return state.cableLay.isLoading;
};

export const getSortingInfo = (state: RootState) => {
  return state.cableLay.sortingInfo;
};

export const getVisibleColumns = (state: RootState) => {
  return state.cableLay.visibleColumns;
};

export const getFilterOptions = (state: RootState) => {
  if (!state.cableLay?.cableLayList) return null;

  const filtersKeyInfo = {
    vesselName: {
      inputLabelText: "Vessel name",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.vesselName)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    owner: {
      inputLabelText: "Owner",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.owner)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    design: {
      inputLabelText: "Design",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.design)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    maxCableCapacity: {
      inputLabelText: "Max cable capacity",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.maxCableCapacity)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    vesselType: {
      inputLabelText: "Vessel type",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.vesselType)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    status: {
      inputLabelText: "Status",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.status)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    vesselCapability: {
      inputLabelText: "Vessel capability",
      options: sortValues(
        [
          ...new Set(
            state.cableLay.cableLayList.flatMap(
              (item) => item.vesselCapability?.split(", ") || [],
            ),
          ),
        ].filter(
          (value) => value !== null && value !== "" && value !== undefined,
        ),
      ).map((value: string) => ({ id: value, value })),
    },
  };
  const filtersVesselDetails = {
    class: {
      inputLabelText: "Class",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.class)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    imo: {
      inputLabelText: "IMO",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.imo)
            .filter((value) => value !== null && value !== undefined),
        ),
      ]).map((value: string) => ({
        id: value.toString(),
        value: value.toString(),
      })),
    },
    flag: {
      inputLabelText: "Flag",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.flag)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    constructedYard: {
      inputLabelText: "Constructed yard",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.constructedYard)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    mmsi: {
      inputLabelText: "MMSI",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.mmsi)
            .filter((value) => value !== null && value !== undefined),
        ),
      ]).map((value: string) => ({
        id: value.toString(),
        value: value.toString(),
      })),
    },
    length: {
      inputLabelText: "Length",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.length)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    width: {
      inputLabelText: "Width",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.width)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    draught: {
      inputLabelText: "Draught",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.draught)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxDeckStrength: {
      inputLabelText: "Max deck strength",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.maxDeckStrength)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    deckArea: {
      inputLabelText: "Deck area",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.deckArea)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxSpeed: {
      inputLabelText: "Max speed",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.maxSpeed)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxPob: {
      inputLabelText: "Max pob",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.maxPob)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
  };
  const filterCableDetails = {
    primaryTurntableCapacity: {
      inputLabelText: "Primary turntable capacity",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.primaryTurntableCapacity)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    primaryOuterDiameter: {
      inputLabelText: "Primary outer diameter",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.primaryOuterDiameter)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    primaryCoreDiameter: {
      inputLabelText: "Primary core diameter",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.primaryCoreDiameter)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    primaryCoreHeight: {
      inputLabelText: "Primary core height",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.primaryCoreHeight)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    secondaryTurntableCapacity: {
      inputLabelText: "Secondary turntable capacity",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.secondaryTurntableCapacity)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    secondaryOuterDiameter: {
      inputLabelText: "Secondary outer diameter",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.secondaryOuterDiameter)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    secondaryCoreHeight: {
      inputLabelText: "Secondary core height",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.secondaryCoreHeight)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxLayingVelocity: {
      inputLabelText: "Max laying velocity",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.maxLayingVelocity)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    bollardPull: {
      inputLabelText: "Bollard pull",
      options: [
        0,
        Math.max(
          ...state.cableLay.cableLayList
            .map((item) => item.bollardPull)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    craneType: {
      inputLabelText: "Crane type",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.craneType)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    craneModel: {
      inputLabelText: "Crane model",
      options: sortValues([
        ...new Set(
          state.cableLay.cableLayList
            .map((item) => item.craneModel)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
  };

  if (Object.values(filtersKeyInfo).some((filter) => !filter.options?.length)) {
    return null;
  }
  if (
    Object.values(filtersVesselDetails).some(
      (filter) => !filter.options?.length,
    )
  ) {
    return null;
  }

  if (
    Object.values(filterCableDetails).some((filter) => !filter.options?.length)
  ) {
    return null;
  }

  const resultKeyInfo: GenericType = {};
  const resultVesselDetails: GenericType = {};
  const resultCableDetails: GenericType = {};

  Object.entries(filtersKeyInfo).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultKeyInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultKeyInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filtersVesselDetails).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultVesselDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultVesselDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filterCableDetails).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultCableDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultCableDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  const groupedFilters = {
    keyInfo: resultKeyInfo,
    vesselDetails: resultVesselDetails,
    cableDetails: resultCableDetails,
  };
  return groupedFilters;
};
