/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import {
  ArrowBackIcon,
  Box,
  Button,
  getEsgianTheme,
  PageHeader,
  SearchIcon,
  TextField,
  Typography,
} from "@esgian/esgianui";

import { searchOptions } from "../../helper/fllter";
import { useAuth } from "../../hooks/use-auth";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getOptions,
  getSearchObject,
  getSearchText,
  getThemeMode,
  getViewTab,
} from "../../store/selector/common";
import {
  setSearchObjectCoordinates,
  setSearchText,
  setViewTab,
} from "../../store/slice/common";
import {
  AuthorizeUser,
  GenericType,
  ROUTES_CONFIG,
  SelectedFilterItem,
  THEME,
  ViewTabEnum,
} from "../../types";
import { StyledAutocomplete } from "../StyledAutocomplete";
import { debounce } from "../WindLeasingAndProjectMap/helper";

const useStyles = () => {
  return {
    tabBtn: {
      height: 24,
      minWidth: 58,
      fontSize: 12,
    },
    searchInput: {
      mr: 2,
      "& .MuiInputBase-input": {
        p: 0,
        height: 32,
      },
      "& .MuiInputBase-root": {
        p: 0,
      },
      "& input": {
        fontSize: 14,
      },
    },
    searchInputIcon: {
      fontSize: 14,
      mx: 0.5,
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      m: 2,
      width: 1,
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    backBtn: {
      mr: 1,
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
  };
};

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);
  const classes = useStyles();
  const viewTab = useSelector(getViewTab);
  const searchText = useSelector(getSearchText);
  const searchObject = useSelector(getSearchObject);
  const { isAuthenticated } = useAuth();
  const routeName = Object.keys(ROUTES_CONFIG).find((item: string) =>
    matchPath(ROUTES_CONFIG?.[item]?.url, location.pathname),
  );
  const routeConfig = ROUTES_CONFIG[routeName || ""];
  const options = searchOptions(
    useSelector(getOptions),
    routeConfig.searchOptions || [],
  ).filter((o: GenericType) => {
    if (isAuthenticated === AuthorizeUser.NonAuthorized) {
      if (o.type !== "Location" && o.type !== "project") {
        return false;
      }
    }
    if (o.type === "Company") {
      if (routeName === "Factory") {
        return o.factoryCount;
      }
      if (routeName === "WindLeasingAndProject") {
        return o.windProjectCount;
      }
      return true;
    }
    return true;
  });
  const [currentSearch, setCurrentSearch] = useState(searchText);

  const setSelectedSearchObjectCoordinates = async (
    value: SelectedFilterItem,
  ) => {
    dispatch(setSearchObjectCoordinates(value));
  };

  const handleBackClick = () => {
    if (routeConfig.backBtnUrl) {
      return navigate(routeConfig.backBtnUrl, { state: { fromDetails: true } });
    }
    navigate(-1);
  };

  const handleSearchTextChange = useCallback(
    debounce((text) => {
      dispatch(setSearchText(text));
    }, 200),
    [],
  );

  useEffect(() => {
    setCurrentSearch(searchText ?? "");
  }, [searchText]);

  return (
    <Box sx={classes.header}>
      <Box sx={classes.titleContainer}>
        {routeConfig.backBtn ? (
          <Button
            onClick={handleBackClick}
            variant="outlined"
            sx={classes.backBtn}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        ) : null}
        <PageHeader title={routeConfig.title} />
      </Box>
      <Box sx={classes.flexCenter}>
        {routeConfig.enableSearch ? (
          <Box
            sx={{ width: 360, mr: routeConfig?.searchInputRightMargin || 2 }}
          >
            {routeConfig.searchAutocomplete && viewTab !== ViewTabEnum.List ? (
              <StyledAutocomplete
                multipleSelection={false}
                options={options}
                selectedItem={searchObject as SelectedFilterItem}
                setSelectedItem={(value) =>
                  setSelectedSearchObjectCoordinates(
                    value as SelectedFilterItem,
                  )
                }
                labelKey="title"
                groupBy="type"
                size="small"
                displayIcon={true}
                disableCloseOnSelect={false}
                searchIcon={true}
                placeholder="Search"
                sorting={true}
                maxHeight="77vh"
                groupItemSubtitle={{
                  Location: routeConfig?.searchOptions?.includes("seaArea")
                    ? "countries, other region types, sea regions"
                    : "countries, other region types",
                }}
              />
            ) : (
              <TextField
                id="outlined-multiline-flexible"
                label="Search"
                sx={{
                  width: 1,
                  height: 40,
                  "& .MuiInputBase-root": { pr: 1 },
                }}
                multiline={false}
                size="small"
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                value={currentSearch}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSearchTextChange(e.target.value);
                  setCurrentSearch(e.target.value);
                }}
              />
            )}
          </Box>
        ) : null}
        {routeConfig?.viewSwitchBtn?.length ? (
          <Box sx={classes.flexCenter}>
            <Typography sx={{ p: 0.5, mr: 0.5 }}>View by:</Typography>
            <Box>
              {routeConfig.viewSwitchBtn.map((item: ViewTabEnum, i: number) => (
                <Button
                  key={item}
                  variant="contained"
                  size="small"
                  color={
                    viewTab === ViewTabEnum[item] ? "primary" : "secondary"
                  }
                  sx={{
                    ...classes.tabBtn,
                    borderRadius: i === 0 ? "4px 0 0 4px" : "0 4px 4px 0",
                    color:
                      viewTab === ViewTabEnum[item] &&
                      theme.palette.common.white,
                  }}
                  onClick={() =>
                    isAuthenticated === AuthorizeUser.Authorized ||
                    item === ViewTabEnum.Map
                      ? dispatch(setViewTab(ViewTabEnum[item]))
                      : navigate(ROUTES_CONFIG.Enquiry.url)
                  }
                >
                  {ViewTabEnum[item]}
                </Button>
              ))}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
