import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import {
  DATE_LABELS,
  DateConfig,
  DROPDOWN_LABELS,
  DropdownConfig,
} from "../../types/filters";

export const getCountryOption = (state: RootState) => {
  return state.common.countries?.map((item) => ({
    id: item.id,
    value: item.name,
    type: "country",
  }));
};

export const getSelectedFilter = (state: RootState) => {
  return state.analytic.selectedFilter;
};

export const getAnalytics = (state: RootState) => {
  return state.analytic.analyticList;
};

export const getFilterOptions = (state: RootState) => {
  const filters = {
    projects: {
      inputLabelText: "Project",
      options: sortValues(
        (state.common.projects ?? [])
          .map((item) => ({
            id: item.windProjectId,
            value: item.name,
          }))
          .filter((item) => item.id !== null && item.value !== ""),
      ),
    },
    company: {
      inputLabelText: "Company",
      options: sortValues(
        (state.common.company ?? [])
          .filter((c) => c.windProjects.length)
          .map((item) => ({
            id: item.company.id,
            value: item.company.companyName,
          }))
          .filter((item) => item.id !== null && item.value !== ""),
      ),
    },
    projectStatus: {
      inputLabelText: "Project Status",
      options: sortValues(
        (state.common.projectStatus ?? [])
          .map((item) => ({
            id: item.id,
            value: item.status,
          }))
          .filter((item) => item.id !== null && item.value !== ""),
      ),
    },
    projectType: {
      inputLabelText: "Project Type",
      options: sortValues(
        (state.common.projectType ?? [])
          .map((item) => ({
            id: item.id,
            value: item.type,
          }))
          .filter((item) => item.id !== null && item.value !== ""),
      ),
    },
    date: {
      inputLabelText: "Date",
      format: "YYYY MM",
    },
  };

  // If any filter is empty, return null (matching base function behavior)
  if (
    Object.values(filters).some(
      (filter) => "options" in filter && !filter.options?.length,
    )
  ) {
    return null;
  }

  const result: GenericType = {};
  Object.entries(filters).forEach(([key, filter]) => {
    if (
      DROPDOWN_LABELS.includes(filter.inputLabelText) &&
      "options" in filter
    ) {
      const dropdownFilter = filter as DropdownConfig;
      result[key] = FilterFactory.createFilter({
        inputLabelText:
          dropdownFilter.inputLabelText as DropdownConfig["inputLabelText"],
        options: sortValues(dropdownFilter.options),
      });
    } else if (
      DATE_LABELS.includes(filter.inputLabelText) &&
      "format" in filter
    ) {
      const dateFilter = filter as DateConfig;
      result[key] = FilterFactory.createFilter({
        inputLabelText:
          dateFilter.inputLabelText as DateConfig["inputLabelText"],
        format: dateFilter.format,
      });
    } else {
      result[key] = null;
    }
  });

  return result;
};
