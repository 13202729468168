import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import {
  DROPDOWN_LABELS,
  DropdownConfig,
  isDropdownConfig,
  isNumericConfig,
  NUMERIC_LABELS,
  NumericConfig,
} from "../../types/filters";

export const getTurbineModelFilterOptions = (state: RootState) => {
  return state.turbineModels.filterOptions;
};

export const getTurbineModelList = (state: RootState) => {
  return state.turbineModels.turbineModelList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.turbineModels.selectedFilter;
};

export const getSortingInfo = (state: RootState) => {
  return state.turbineModels.sortingInfo;
};

export const getFilterOptions = (state: RootState) => {
  if (!state.turbineModels?.turbineModelList) return null;

  const filters = {
    suppliers: {
      inputLabelText: "Suppliers",
      options: sortValues([
        ...new Set(
          state.turbineModels.turbineModelList
            .map((item) => item.suppliers)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    turbineModel: {
      inputLabelText: "Turbine model",
      options: sortValues([
        ...new Set(
          state.turbineModels.turbineModelList
            .map((item) => item.turbineModel)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    generatorSystem: {
      inputLabelText: "Generator system",
      options: sortValues([
        ...new Set(
          state.turbineModels.turbineModelList
            .map((item) => item.generatorSystem)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    turbineNominalPower: {
      inputLabelText: "Turbine nominal power",
      options: [
        0,
        Math.max(
          ...state.turbineModels.turbineModelList
            .map((item) => item.turbineNominalPower)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    rotorDiameter: {
      inputLabelText: "Rotor diameter",
      options: [
        0,
        Math.max(
          ...state.turbineModels.turbineModelList
            .map((item) => item.rotorDiameter)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    sweptArea: {
      inputLabelText: "Swept area",
      options: [
        0,
        Math.max(
          ...state.turbineModels.turbineModelList
            .map((item) => item.sweptArea)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    numberOfBlades: {
      inputLabelText: "Number of blades",
      options: [
        0,
        Math.max(
          ...state.turbineModels.turbineModelList
            .map((item) => item.numberOfBlades)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    cutInSpeed: {
      inputLabelText: "Cut in speed",
      options: [
        0,
        Math.max(
          ...state.turbineModels.turbineModelList
            .map((item) => item.cutInSpeed)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    cutOutSpeed: {
      inputLabelText: "Cut out speed",
      options: [
        0,
        Math.max(
          ...state.turbineModels.turbineModelList
            .map((item) => item.cutOutSpeed)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    ratedWindSpeed: {
      inputLabelText: "Rated wind speed",
      options: [
        0,
        Math.max(
          ...state.turbineModels.turbineModelList
            .map((item) => item.ratedWindSpeed)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
  };

  if (Object.values(filters).some((filter) => !filter.options?.length)) {
    return null;
  }

  const result: GenericType = {};

  Object.entries(filters).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  return result;
};
