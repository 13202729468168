import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";

import { api } from "../../api/api";
import { getErrorMessage } from "../../helper/errorHandling";
import { safelyCallApi } from "../../helper/requestWrapper";
import {
  getPowerOutputCompareWindProjects,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { GenericType, MESSAGE } from "../../types";
import { useSelector } from "../use-selector";

import { useLookupQueries } from "./useLookupQueries";

const getPowerDataFormatted = (
  data: GenericType,
  lookupProjects: GenericType,
  lookupCountries: GenericType,
  detailedProjectsObjects: GenericType,
) => {
  const res: GenericType = { ...data };
  Object.keys(data).forEach((projectId) => {
    const lookupProject = lookupProjects[projectId];
    const detailedProjectObject = detailedProjectsObjects[projectId];

    res[projectId].name = lookupProject?.name;
    res[projectId].numTurbines = lookupProject?.numTurbines;
    res[projectId].operationDate = lookupProject?.operationDate;
    res[projectId].id = projectId;
    res[projectId].countryId = lookupProject?.countryId;
    res[projectId].countryName = lookupCountries[lookupProject?.countryId].name;
    res[projectId].capacityMw = lookupProject?.capacityMw;
    if (detailedProjectObject) {
      res[projectId].developers = detailedProjectObject.developers
        ?.map((company: GenericType) => company.companyName)
        .join(", ");
      res[projectId].seaArea = detailedProjectObject.seaRegion?.[0]?.name;
      res[projectId].turbineSuppliers =
        detailedProjectObject.windProject?.turbineSuppliers
          ?.map((item: GenericType) => item.companyName)
          .join(", ");
      res[projectId].turbineModels =
        detailedProjectObject.windProject.turbineModels
          ?.map((item: GenericType) => item.name)
          .join(", ");
      res[projectId].turbinePowers =
        detailedProjectObject.windProject.turbineModels
          ?.map((item: GenericType) => item.ratedPowerMw)
          .join(", ");
    }
  });

  return res;
};

export const usePowerGenQueries = () => {
  const selectedProject = useSelector(getPowerOutputWindProject);
  const compareWindProjects = useSelector(getPowerOutputCompareWindProjects);
  const { lookupProjectsObject, lookupCountriesObject, detailedProjectObject } =
    useLookupQueries();
  const queryClient = useQueryClient(); // Access queryClient instance
  const location = useLocation();

  const powerDataQuery = useQuery({
    queryKey: ["powerGenData"],
    enabled:
      !!lookupProjectsObject &&
      !!lookupCountriesObject &&
      !!detailedProjectObject &&
      ["/power-generation", "/power-generation-details"].includes(
        location.pathname,
      ),
    placeholderData: null,
    queryFn: () => {
      return safelyCallApi(
        api.powerOutputAggregated.getPowerOutputAggregated({
          projectIds: Object.keys(lookupProjectsObject ?? {}),
          startTimestamp: moment().subtract(3, "year"),
          endTimestamp: moment().subtract(1, "day"),
          includeHistorical: false,
          aggregationLevel: "day", // TODO: Change to hour when redis works
        }),
      )
        .then(({ status, data }) => {
          if (status !== 200) return null;

          return getPowerDataFormatted(
            data,
            lookupProjectsObject ?? {},
            lookupCountriesObject || {},
            detailedProjectObject ?? {},
          );
        })
        .catch((err) => {
          toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
          return null;
        });
    },
  });

  const powerDataFullProjectQuery = useQuery({
    queryKey: ["powerGenDataFull", selectedProject, compareWindProjects],
    enabled:
      !!lookupProjectsObject &&
      !!lookupCountriesObject &&
      !!selectedProject &&
      ["/power-generation-details"].includes(location.pathname),
    placeholderData: null,
    queryFn: ({ queryKey }) => {
      // Get the list of IDs to fetch
      const ids = [
        selectedProject?.id,
        ...(compareWindProjects?.map(({ id }) => id) ?? []),
      ];

      // get the unique queryKey from last time this function was triggered
      const oldKeys = queryClient.getQueryData<unknown>([
        "keys",
        "powerGenDataFull",
      ]);

      let previousData = {};
      // Get previously fetched data from the React Query cache
      if (oldKeys && Array.isArray(oldKeys)) {
        previousData = queryClient.getQueryData<GenericType>(oldKeys) || {};
      }

      // If there is no previous data, all IDs are considered new
      const fetchedIds = previousData ? Object.keys(previousData) : [];

      // Filter out the IDs that have already been fetched (new IDs only)
      const idsToFetch = ids.filter(
        (id) => id && !fetchedIds.includes(id.toString()),
      );

      // If no new IDs to fetch, return the previous data
      if (idsToFetch.length === 0) {
        return Promise.resolve(previousData);
      }

      // Make the API request for new IDs
      return safelyCallApi(
        api.powerOutputAggregated.getPowerOutputAggregated({
          projectIds: idsToFetch,
          includeHistorical: true,
          aggregationLevel: "hour",
          historyRollup: "day",
        }),
      )
        .then(({ status, data }) => {
          if (status !== 200) return null;

          // Format the new data
          const formattedData = getPowerDataFormatted(
            data,
            lookupProjectsObject ?? {},
            lookupCountriesObject ?? {},
            {},
          );

          // Merge the new data with the existing data
          const mergedData = { ...previousData, ...formattedData };

          // Store the current queryKey that is used to retrieve the data
          queryClient.setQueryData(["keys", "powerGenDataFull"], queryKey);

          // Update the React Query cache with the merged data
          queryClient.setQueryData(
            [
              "powerGenDataFull",
              selectedProject?.id,
              compareWindProjects?.map(({ id }) => id).join(","),
            ],
            mergedData,
          );

          return mergedData;
        })
        .catch((err) => {
          toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
          return null;
        });
    },
  });

  const resetLookupData = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ["lookupProjects"] });
  }, [queryClient]);

  return {
    powerDataQuery,
    powerDataFullProjectQuery,
    resetLookupData,
  };
};
