import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import {
  DROPDOWN_LABELS,
  DropdownConfig,
  isDropdownConfig,
  isNumericConfig,
  NUMERIC_LABELS,
  NumericConfig,
} from "../../types/filters";

export const getInstallationList = (state: RootState) => {
  return state.installation.installationList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.installation.selectedFilter;
};

export const getInstallationFilterOptions = (state: RootState) => {
  return state.installation.filterOptions;
};

export const getIsLoading = (state: RootState) => {
  return state.installation.isLoading;
};

export const getSortingInfo = (state: RootState) => {
  return state.installation.sortingInfo;
};

export const getVisibleColumns = (state: RootState) => {
  return state.installation.visibleColumns;
};

export const getImoMapping = (state: RootState) => {
  return state.installation.imoMapping;
};

export const getFilterOptions = (state: RootState) => {
  if (!state.installation?.installationList) return null;

  const filtersKeyInfo = {
    vesselName: {
      inputLabelText: "Vessel name",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.vesselName)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    owner: {
      inputLabelText: "Owner",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.owner)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    design: {
      inputLabelText: "Design",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.design)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    craneMake: {
      inputLabelText: "Crane make",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.craneMake)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    craneMaxCapacity: {
      inputLabelText: "Crane max capacity",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) =>
              item.craneMaxCapacity
                .split(", ")
                .map((val) => Number(val.trim())),
            )
            .flat()
            .filter(
              (value) => value !== null && value !== undefined && !isNaN(value),
            ),
        ),
      ],
    },
    maxLiftAboveDeck: {
      inputLabelText: "Max lift above deck",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) =>
              item.maxLiftAboveDeck
                .split(", ")
                .map((val) => Number(val.trim())),
            )
            .flat()
            .filter(
              (value) => value !== null && value !== undefined && !isNaN(value),
            ),
        ),
      ],
    },
    deckArea: {
      inputLabelText: "Deck area",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.deckArea)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    dateOfDelivery: {
      inputLabelText: "Date of delivery",
      format: "YYYY MM",
    },
    newbuild: {
      inputLabelText: "Newbuild",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.newbuild)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    vesselType: {
      inputLabelText: "Vessel type",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.vesselType)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    status: {
      inputLabelText: "Status",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.status)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    vesselCapability: {
      inputLabelText: "Vessel capability",
      options: sortValues(
        [
          ...new Set(
            state.installation.installationList.flatMap(
              (item) => item.vesselCapability?.split(", ") || [],
            ),
          ),
        ].filter(
          (value) => value !== null && value !== "" && value !== undefined,
        ),
      ).map((value: string) => ({ id: value, value })),
    },
  };
  const filtersVesselDetails = {
    imo: {
      inputLabelText: "IMO",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.imo)
            .filter((value) => value !== null && value !== undefined),
        ),
      ]).map((value: string) => ({
        id: value.toString(),
        value: value.toString(),
      })),
    },
    flag: {
      inputLabelText: "Flag",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.flag)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    constructedYard: {
      inputLabelText: "Constructed yard",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.constructedYard)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    mmsi: {
      inputLabelText: "MMSI",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.mmsi)
            .filter((value) => value !== null && value !== undefined),
        ),
      ]).map((value: string) => ({
        id: value.toString(),
        value: value.toString(),
      })),
    },
    width: {
      inputLabelText: "Width",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.width)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    draught: {
      inputLabelText: "Draught",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.draught)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxDeckStrength: {
      inputLabelText: "Max deck strength",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.maxDeckStrength)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxDeckLoadCapacity: {
      inputLabelText: "Max deck load capacity",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.maxDeckLoadCapacity)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxSpeed: {
      inputLabelText: "Max speed",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.maxSpeed)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxPob: {
      inputLabelText: "Max pob",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.maxPob)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    minWaterDepth: {
      inputLabelText: "Min water depth",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.minWaterDepth)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxWaterDepth: {
      inputLabelText: "Max water depth",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.maxWaterDepth)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
  };
  const filterJackingDetails = {
    preLoadCapacity: {
      inputLabelText: "Pre load capacity",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.preLoadCapacity)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    legLength: {
      inputLabelText: "Leg length",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.legLength)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    elevatingJackingSpeed: {
      inputLabelText: "Elevating jacking speed",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.elevatingJackingSpeed)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    maxJackingLoad: {
      inputLabelText: "Max jacking load",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .map((item) => item.maxJackingLoad)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    jackingSystemMake: {
      inputLabelText: "Jacking system make",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.jackingSystemMake)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    jackingSystemModel: {
      inputLabelText: "Jacking system model",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.jackingSystemModel)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
  };
  const filterCraneDetails = {
    craneType: {
      inputLabelText: "Crane type",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.craneType)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    craneModel: {
      inputLabelText: "Crane model",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.craneModel)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    hookHeightAboveDeck: {
      inputLabelText: "Hook height above deck",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .flatMap((item) => item.hookHeightAboveDeck)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    longBoomWeight: {
      inputLabelText: "Long boom weight",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .flatMap((item) => item.longBoomWeight)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    longBoomRadius: {
      inputLabelText: "Long boom radius",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .flatMap((item) => item.longBoomRadius)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    shortBoomWeight: {
      inputLabelText: "Short boom weight",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .flatMap((item) => item.shortBoomWeight)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    shortBoomRadius: {
      inputLabelText: "Short boom radius",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .flatMap((item) => item.shortBoomRadius)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    boomLength: {
      inputLabelText: "Boom length",
      options: [
        0,
        Math.max(
          ...state.installation.installationList
            .flatMap((item) => item.boomLength)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    futureCraneUpgrade: {
      inputLabelText: "Future crane upgrade",
      options: sortValues([
        ...new Set(
          state.installation.installationList
            .map((item) => item.futureCraneUpgrade)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
  };

  if (
    Object.values(filtersKeyInfo).some((filter) => {
      if ("options" in filter) {
        return filter.options.size === 0; // Check if options Set is empty
      } else if ("format" in filter) {
        return !filter.format; // Check if format is missing
      }
      return false; // Default return if neither options nor format is present
    })
  ) {
    return null;
  }
  if (
    Object.values(filtersVesselDetails).some(
      (filter) => !filter.options?.length,
    )
  ) {
    return null;
  }
  if (
    Object.values(filterJackingDetails).some(
      (filter) => !filter.options?.length,
    )
  ) {
    return null;
  }
  if (
    Object.values(filterCraneDetails).some((filter) => !filter.options?.length)
  ) {
    return null;
  }

  const resultKeyInfo: GenericType = {};
  const resultVesselDetails: GenericType = {};
  const resultJackingDetails: GenericType = {};
  const resultCraneDetails: GenericType = {};

  Object.entries(filtersKeyInfo).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultKeyInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultKeyInfo[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filtersVesselDetails).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultVesselDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultVesselDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filterJackingDetails).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultJackingDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultJackingDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  Object.entries(filterCraneDetails).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        resultCraneDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        resultCraneDetails[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });
  const groupedFilters = {
    keyInfo: resultKeyInfo,
    vesselDetails: resultVesselDetails,
    jackingDetails: resultJackingDetails,
    craneDetails: resultCraneDetails,
  };
  return groupedFilters;
};
