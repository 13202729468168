import { useEffect } from "react";
import { Box } from "@esgian/esgianui";

import { InstallationDrawer } from "../../components/Drawer/Installation";
import { InstallationList } from "../../components/InstallationList";
import { FilterModal } from "../../components/Modal/FilterModal";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getFilterModal,
  getSelectedInstallationId,
  getVesselType,
} from "../../store/selector/common";
import {
  getFilterOptions,
  getSelectedFilter,
} from "../../store/selector/installation";
import {
  fetchLookup,
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedInstallationId,
} from "../../store/slice/common";
import {
  fetchInstallationList,
  setSelectedFilter,
} from "../../store/slice/installation";

export const Installation = () => {
  const dispatch = useDispatch();
  const isFilterModalOpen = useSelector(getFilterModal);
  const vesselType = useSelector(getVesselType);
  const options = useSelector(getFilterOptions);
  const selectedFilter = useSelector(getSelectedFilter);
  const selectedInstallationId = useSelector(getSelectedInstallationId);

  useEffect(() => {
    dispatch(fetchLookup(["vesselType"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vesselType?.length) {
      dispatch(fetchInstallationList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vesselType]);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <InstallationDrawer
        id={selectedInstallationId}
        onClose={() => dispatch(setSelectedInstallationId(null))}
      />
      {options ? (
        <>
          <FilterModal
            open={isFilterModalOpen}
            handleClose={() => dispatch(setFilterModal(false))}
            onChange={(filterValues) =>
              dispatch(setSelectedFilter(filterValues))
            }
            groupsOption={options}
            selectedFilter={selectedFilter}
          />
        </>
      ) : null}
      <InstallationList />
    </Box>
  );
};
