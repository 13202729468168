import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import { DROPDOWN_LABELS, DropdownConfig } from "../../types/filters";

export const getLeaseRoundTimelineEvents = (state: RootState) => {
  return state.leaseRoundTimeline.events;
};

export const getLeaseRoundTimelineResource = (state: RootState) => {
  return state.leaseRoundTimeline.resource;
};

export const getLeaseRoundTimelineStartAndEndDate = (state: RootState) => {
  return {
    startDate: state.leaseRoundTimeline.startDate,
    endDate: state.leaseRoundTimeline.endDate,
  };
};

export const getLeaseRoundIsLoading = (state: RootState) => {
  return state.leaseRoundTimeline.isLoading;
};

export const getLeaseRoundTotalCount = (state: RootState) => {
  return state.leaseRoundTimeline.totalLeaseRoundCount;
};

export const getSelectedFilter = (state: RootState) => {
  return state.leaseRoundTimeline.selectedFilter;
};

export const getFilterOptions = (state: RootState) => {
  const filters = {
    name: {
      inputLabelText: "Location",
      options: sortValues(
        (state.leaseRoundTimeline.resource ?? [])
          .map((item) => ({
            id: item.id,
            value: item.name,
          }))
          .filter((item) => item.id !== null && item.value !== ""),
      ),
    },
    leaseRoundStatus: {
      inputLabelText: "Lease round status",
      options: sortValues(
        [
          ...new Set(
            (state.common.leaseRoundStatus ?? [])
              .map((item) => ({
                id: item.id,
                value: item.status,
              }))
              .filter((value) => value !== null && value !== undefined),
          ),
        ].map((item) => ({
          id: item.id,
          value: item.value,
        })),
      ),
    },
  };

  // If any filter is empty, return null (matching base function behavior)
  if (
    Object.values(filters).some(
      (filter) => "options" in filter && !filter.options?.length,
    )
  ) {
    return null;
  }

  const result: GenericType = {};
  Object.entries(filters).forEach(([key, filter]) => {
    if (
      DROPDOWN_LABELS.includes(filter.inputLabelText) &&
      "options" in filter
    ) {
      const dropdownFilter = filter as DropdownConfig;
      result[key] = FilterFactory.createFilter({
        inputLabelText:
          dropdownFilter.inputLabelText as DropdownConfig["inputLabelText"],
        options: sortValues(dropdownFilter.options),
      });
    } else {
      result[key] = null;
    }
  });

  return result;
};
