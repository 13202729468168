import { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@esgian/esgianui";

import { StyledAutocomplete } from "../../components";
import { AnalyticsChart } from "../../components/AnalyticsChart";
import { FilterBtn } from "../../components/FilterBtn";
import { FilterModal } from "../../components/Modal/FilterModal";
import { calculateFilterCount } from "../../helper/fllter";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getAnalytics,
  getCountryOption,
  getFilterOptions,
  getSelectedFilter,
} from "../../store/selector/analytics";
import { getCountries, getFilterModal } from "../../store/selector/common";
import { fetchAnalytics, setSelectedFilter } from "../../store/slice/analytics";
import {
  fetchLookup,
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
} from "../../store/slice/common";
import {
  AnalyticsSubTabEnum,
  AnalyticsTabEnum,
  GenericType,
  SelectedFilterItem,
} from "../../types";
import { Analytic } from "../../types/analytics";

export const Analytics = () => {
  const dispatch = useDispatch();
  const isFilterModalOpen = useSelector(getFilterModal);
  const countries = useSelector(getCountryOption);
  const analyticsList = useSelector(getAnalytics);
  const countryList = useSelector(getCountries);
  const options = useSelector(getFilterOptions);
  const selectedFilter = useSelector(getSelectedFilter);

  const [tab, setTab] = useState<AnalyticsTabEnum>(AnalyticsTabEnum.PerYear);
  const [subTab, setSubTab] = useState<AnalyticsSubTabEnum>(
    AnalyticsSubTabEnum.Capacity,
  );
  const [selectedCountries, setSelectedCountries] = useState<
    SelectedFilterItem[]
  >([]);
  const [filteredData, setFilteredData] = useState<Analytic[]>([]);
  const [exportName, setExportName] = useState("");

  const newCountries = countries.map((item) => {
    return {
      id: item.id,
      value: item.value,
    };
  });

  useEffect(() => {
    dispatch(
      fetchLookup(["projectStatus", "projectType", "projects", "company"]),
    );
  }, [dispatch]);

  const data = useMemo(() => {
    if (!filteredData || !newCountries.length) {
      return;
    }

    const aggregateData = (list: Analytic[]) =>
      list.reduce(
        (acc: GenericType, { year, countryId, capacity, numberOfTurbines }) => {
          if (!acc[year]) {
            acc[year] = {};
          }
          const key =
            subTab === AnalyticsSubTabEnum.Capacity
              ? "capacity"
              : "numberOfTurbines";
          acc[year][countryId] =
            (acc[year][countryId] || 0) +
            (key === "capacity" ? capacity : numberOfTurbines);
          return acc;
        },
        {},
      );

    const computeCumulative = (list: ReturnType<typeof aggregateData>) => {
      const years = Object.keys(list).map(Number).sort();
      const countryIds = Array.from(
        new Set(Object.values(list).flatMap((year) => Object.keys(year))),
      ).map(Number);

      const cumulativeData: GenericType = {};

      years.forEach((year) => {
        cumulativeData[year] = {};
        countryIds.forEach((countryId) => {
          cumulativeData[year][countryId] = 0;
        });
      });

      years.forEach((year, index) => {
        const previousYearData =
          index > 0 ? cumulativeData[years[index - 1]] : {};
        countryIds.forEach((countryId) => {
          const currentYearValue = list[year][countryId] || 0;
          const previousYearValue = previousYearData[countryId] || 0;
          cumulativeData[year][countryId] =
            previousYearValue + currentYearValue;
        });
      });

      return cumulativeData;
    };

    const processedData =
      tab === AnalyticsTabEnum.Cumulative
        ? computeCumulative(aggregateData(filteredData))
        : aggregateData(filteredData);
    const categories = Object.keys(processedData).sort();

    if (
      !selectedCountries.length ||
      selectedCountries.length === newCountries.length
    ) {
      const values = categories.map((year) =>
        Object.values(processedData[year]).reduce(
          (sum: number, num) => sum + (num as number),
          0,
        ),
      );
      return {
        series: [{ name: "All", data: values }],
        categories,
      };
    } else {
      const filteredList = filteredData.filter(({ countryId }) =>
        selectedCountries.some((country) => country.id === countryId),
      );

      const groupedData =
        tab === AnalyticsTabEnum.Cumulative
          ? computeCumulative(aggregateData(filteredList))
          : aggregateData(filteredList);
      const series = selectedCountries.map((country) => ({
        name: countryList.find((item) => item.id === country.id)?.name,
        data: categories.map((year) => groupedData?.[year]?.[country.id] || 0),
      }));

      return { series, categories };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, newCountries.length, countryList, subTab, tab]);

  useEffect(() => {
    let countryData = analyticsList || [];
    const ids =
      selectedCountries.length === newCountries.length ||
      !selectedCountries.length
        ? []
        : selectedCountries.map((country) => country.id);
    if (ids.length) {
      countryData =
        analyticsList?.filter(
          (item: Analytic) => item.countryId && ids.includes(item.countryId),
        ) || [];
    }
    setFilteredData(countryData);
  }, [
    newCountries.length,
    dispatch,
    selectedCountries,
    analyticsList,
    tab,
    subTab,
  ]);

  useEffect(() => {
    dispatch(fetchAnalytics());
  }, [dispatch, selectedFilter]);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "per-year") {
      if (subTab === "capacity") {
        setExportName("Analytics - Per year - Capacity (MW)");
      }
      if (subTab === "numberTurbine") {
        setExportName("Analytics - Per year - Number of turbines");
      }
    }
    if (tab === "cumulative") {
      if (subTab === "capacity") {
        setExportName("Cumulative - Per year - Capacity (MW)");
      }
      if (subTab === "numberTurbine") {
        setExportName("Cumulative - Per year - Number of turbines");
      }
    }
  }, [tab, subTab]);

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <FormControl component="fieldset" sx={{ px: 2 }} fullWidth={true}>
          <Grid
            container={true}
            alignItems="center"
            spacing={2}
            fullWidth={true}
          >
            <Grid item={true}>
              <RadioGroup
                row={true}
                aria-labelledby="view-radio-buttons"
                name="view-radio-buttons"
                value={tab}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setTab(event.target.value as AnalyticsTabEnum)
                }
              >
                <FormControlLabel
                  value={AnalyticsTabEnum.PerYear}
                  control={<Radio />}
                  label="Per Year"
                />
                <FormControlLabel
                  value={AnalyticsTabEnum.Cumulative}
                  control={<Radio />}
                  label="Cumulative"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <FormControl component="fieldset" sx={{ px: 2 }} fullWidth={true}>
          <Grid
            container={true}
            alignItems="center"
            spacing={2}
            fullWidth={true}
          >
            <Grid item={true}>
              <RadioGroup
                row={true}
                aria-labelledby="view-radio-buttons"
                name="view-radio-buttons"
                value={subTab}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSubTab(event.target.value as AnalyticsSubTabEnum)
                }
              >
                <FormControlLabel
                  value={AnalyticsSubTabEnum.Capacity}
                  control={<Radio />}
                  label="Capacity (MW)"
                />
                <FormControlLabel
                  value={AnalyticsSubTabEnum.NumberTurbines}
                  control={<Radio />}
                  label="Number of turbines"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl fullWidth={true} sx={{ m: 2, width: 300 }}>
          <StyledAutocomplete
            disableClearable={true}
            options={newCountries}
            selectedItem={selectedCountries}
            setSelectedItem={(value) => {
              if (!Array.isArray(value)) {
                setSelectedCountries([]);
                return;
              }
              setSelectedCountries(value as SelectedFilterItem[]);
            }}
            labelKey="value"
          />
        </FormControl>
        <FilterBtn count={calculateFilterCount(selectedFilter)} />
      </Box>
      {data?.categories ? (
        <AnalyticsChart
          series={data.series}
          categories={data.categories || []}
          exportName={exportName}
        />
      ) : null}
      {options ? (
        <>
          <FilterModal
            open={isFilterModalOpen}
            handleClose={() => dispatch(setFilterModal(false))}
            onChange={(filterValues) =>
              dispatch(setSelectedFilter(filterValues))
            }
            selectedFilter={selectedFilter}
            options={options}
          />
        </>
      ) : null}
    </Box>
  );
};
