export enum RegionType {
  commercialRegionsShips = 1,
  weatherRegionsShips,
  leaseArea,
  windProjectArea,
  seaArea,
  continent,
  political,
  country,
  terminal,
  anchorageSite,
  canal,
  unknown = 0,
}
