import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import {
  DATE_LABELS,
  DateConfig,
  DROPDOWN_LABELS,
  DropdownConfig,
} from "../../types/filters";

export const getFactoryList = (state: RootState) => {
  return state.factory.factoryList;
};

export const getFactoryInfoList = (state: RootState) => {
  return state.factory.factoryInfoList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.factory.selectedFilter;
};

export const getSortingInfo = (state: RootState) => {
  return state.factory.sortingInfo;
};

export const getFilterOptions = (state: RootState, isMap: boolean) => {
  const equipmentTypes =
    state.factory.equipmentTypes || state.common.equipmentType;
  const factoryNames = isMap
    ? sortValues(
        (state?.factory?.factoryList ?? [])
          .map((item) => ({
            id: item.id,
            value: item?.name,
          }))
          .filter(
            (item) =>
              item.id !== null && item.value !== "" && item.value !== undefined,
          ),
      )
    : sortValues(
        (state?.factory?.factoryInfoList ?? [])
          .map((item) => ({
            id: item.id,
            value: item?.name,
          }))
          .filter(
            (item) =>
              item.id !== null && item.value !== "" && item.value !== undefined,
          ),
      );

  const filters = {
    country: {
      inputLabelText: "Country",
      options: sortValues([
        ...new Set(
          (state.factory.factoryInfoList ?? [])
            .map((item) => item.country)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            ) || [],
        ),
      ]).map((value: string) => ({
        id: value,
        value,
      })),
    },
    name: {
      inputLabelText: "Factory",
      options: factoryNames,
    },
    factoryOwners: {
      inputLabelText: "Company",
      options: sortValues(
        (state?.common?.company ?? [])
          .filter(
            (item) =>
              item?.company?.companyShortName && item?.factories?.length,
          )
          .map((item) => ({
            id: item?.company?.id,
            value: item?.company?.companyShortName,
          }))
          .filter(
            (item) =>
              item.id !== null && item.value !== "" && item.value !== undefined,
          ),
      ),
    },
    factoryFunctions: {
      inputLabelText: "Factory Functions",
      options: sortValues(
        (state.common.factoryFunctions ?? [])
          .map((item) => ({
            id: item.id,
            value: item.function,
          }))
          .filter(
            (item) =>
              item.id !== null && item.value !== "" && item.value !== undefined,
          ),
      ),
    },
    equipmentTypes: {
      inputLabelText: "Equipment Type",
      options: sortValues(
        (equipmentTypes ?? [])
          .map((item) => ({
            id: item.id,
            value: item.type,
          }))
          .filter(
            (item) =>
              item.id !== null && item.value !== "" && item.value !== undefined,
          ),
      ),
    },
    factoryStatus: {
      inputLabelText: "Factory Status",
      options: sortValues(
        (state.common.factoryStatus ?? [])
          .map((item) => ({
            id: item.id,
            value: item.status,
          }))
          .filter(
            (item) =>
              item.id !== null && item.value !== "" && item.value !== undefined,
          ),
      ),
    },
  };

  if (Object.values(filters).some((filter) => !filter.options?.length)) {
    return null;
  }

  const result: GenericType = {};
  Object.entries(filters).forEach(([key, filter]) => {
    if (
      DROPDOWN_LABELS.includes(filter.inputLabelText) &&
      "options" in filter
    ) {
      const dropdownFilter = filter as DropdownConfig;
      result[key] = FilterFactory.createFilter({
        inputLabelText:
          dropdownFilter.inputLabelText as DropdownConfig["inputLabelText"],
        options: sortValues(dropdownFilter.options),
      });
    } else if (
      DATE_LABELS.includes(filter.inputLabelText) &&
      "format" in filter
    ) {
      const dateFilter = filter as DateConfig;
      result[key] = FilterFactory.createFilter({
        inputLabelText:
          dateFilter.inputLabelText as DateConfig["inputLabelText"],
        format: dateFilter.format,
      });
    } else {
      result[key] = null;
    }
  });

  return result;
};
