import {
  DateConfig,
  DropdownConfig,
  FilterConfig,
  NumericConfig,
} from "../types/filters";

import { DateFilter } from "./dateFilter";
import { DropdownFilter } from "./dropdownFilter";
import { NumericFilter } from "./numericFilter";

export class FilterFactory {
  private static filtersMap = {
    Project: DropdownFilter,
    Company: DropdownFilter,
    "Project Status": DropdownFilter,
    Date: DateFilter,
    Country: DropdownFilter,
    Factory: DropdownFilter,
    "Factory Functions": DropdownFilter,
    "Equipment Type": DropdownFilter,
    "Factory Status": DropdownFilter,
    "Wind project": DropdownFilter,
    "Wind project status": DropdownFilter,
    "PPA type": DropdownFilter,
    Buyer: DropdownFilter,
    Seller: DropdownFilter,
    "Contract award date": DateFilter,
    Supplier: DropdownFilter,
    "Wind farm": DropdownFilter,
    "Contract status": DropdownFilter,
    "Turbine model": DropdownFilter,
    "Foundation type": DropdownFilter,
    "Cable type": DropdownFilter,
    "Voltage kV": NumericFilter,
    "Output current": DropdownFilter,
    Substation: DropdownFilter,
    Components: DropdownFilter,
    "Technology type": DropdownFilter,
    "Floating technology": DropdownFilter,
    "Vessel name": DropdownFilter,
    "Vessel owner": DropdownFilter,
    "Start date": DateFilter,
    "End date": DateFilter,
    "Project Type": DropdownFilter,
    "Contracted equipment type": DropdownFilter,
    Owner: DropdownFilter,
    Design: DropdownFilter,
    "Max cable capacity": NumericFilter,
    "Vessel type": DropdownFilter,
    Status: DropdownFilter,
    "Vessel capability": DropdownFilter,
    Class: DropdownFilter,
    IMO: DropdownFilter,
    Flag: DropdownFilter,
    "Constructed yard": DropdownFilter,
    MMSI: DropdownFilter,
    "Crane type": DropdownFilter,
    "Crane model": DropdownFilter,
    Length: NumericFilter,
    Width: NumericFilter,
    Draught: NumericFilter,
    "Max deck strength": NumericFilter,
    "Deck area": NumericFilter,
    "Max speed": NumericFilter,
    "Max pob": NumericFilter,
    "Primary turntable capacity": NumericFilter,
    "Primary outer diameter": NumericFilter,
    "Primary core diameter": NumericFilter,
    "Primary core height": NumericFilter,
    "Secondary turntable capacity": NumericFilter,
    "Secondary outer diameter": NumericFilter,
    "Secondary core height": NumericFilter,
    "Max laying velocity": NumericFilter,
    "Bollard pull": NumericFilter,
    "Crane make": DropdownFilter,
    "Crane max capacity": NumericFilter,
    "Max lift above deck": NumericFilter,
    "Date of delivery": DateFilter,
    Newbuild: DropdownFilter,
    "Max deck load capacity": NumericFilter,
    "Min water depth": NumericFilter,
    "Max water depth": NumericFilter,
    "Pre load capacity": NumericFilter,
    "Leg length": NumericFilter,
    "Elevating jacking speed": NumericFilter,
    "Max jacking load": NumericFilter,
    "Jacking system make": DropdownFilter,
    "Jacking system model": DropdownFilter,
    "Hook height above deck": NumericFilter,
    "Long boom weight": NumericFilter,
    "Long boom radius": NumericFilter,
    "Short boom weight": NumericFilter,
    "Short boom radius": NumericFilter,
    "Boom length": NumericFilter,
    "Future crane upgrade": DropdownFilter,
    Name: DropdownFilter,
    "Current installed capacity (MW)": NumericFilter,
    Developers: DropdownFilter,
    "Sea area": DropdownFilter,
    "Turbine supplier": DropdownFilter,
    "Turbine power (MW)": NumericFilter,
    Location: DropdownFilter,
    "Lease round status": DropdownFilter,
    Suppliers: DropdownFilter,
    "Generator system": DropdownFilter,
    "Turbine nominal power": NumericFilter,
    "Rotor diameter": NumericFilter,
    "Swept area": NumericFilter,
    "Number of blades": NumericFilter,
    "Cut in speed": NumericFilter,
    "Cut out speed": NumericFilter,
    "Rated wind speed": NumericFilter,
    Technology: DropdownFilter,
    "Floater type": DropdownFilter,
    "Floater material": DropdownFilter,
    "Floating material": DropdownFilter,
    "Lease area type": DropdownFilter,
    "Project status": DropdownFilter,
    "Project type": DropdownFilter,
    "Operation period": DateFilter,
    "Award period": DateFilter,
    "Number of turbines": NumericFilter,
    "Decommissioning date": DateFilter,
    "Lease round": DropdownFilter,
    "Sea region": DropdownFilter,
    "Maximum water depth": NumericFilter,
    "Foundation supplier": DropdownFilter,
    "Main port name": DropdownFilter,
    "Turbine installer": DropdownFilter,
    "Cable installer": DropdownFilter,
    "Foundation installer": DropdownFilter,
    Capacity: NumericFilter,
    Hydrogen: DropdownFilter,
    "Hydrogen project": DropdownFilter,
    "Hydrogen developer": DropdownFilter,
    "Hydrogen technology": DropdownFilter,
    "Date online": DateFilter,
    "Oil and gas": DropdownFilter,
    "Oil and gas fields": DropdownFilter,
  };

  // Factory method to create the filter based on the inputLabelText
  static createFilter(config: FilterConfig) {
    const FilterClass = this.filtersMap[config.inputLabelText];
    if (!FilterClass) {
      throw new Error(
        `Filter for inputLabelText "${config.inputLabelText}" not found.`,
      );
    }

    if (FilterClass === DropdownFilter) {
      // TypeScript understands that config is DropdownConfig here
      const dropdownConfig = config as DropdownConfig; // Narrowing down the type
      if (!dropdownConfig.options || dropdownConfig.options.length === 0) {
        throw new Error("DropdownFilter requires options.");
      }
      return new FilterClass(dropdownConfig);
    }

    if (FilterClass === DateFilter) {
      // TypeScript understands that config is DateConfig here
      const dateConfig = config as DateConfig;
      if (!dateConfig.format) {
        throw new Error("DateFilter requires a format.");
      }
      // Return the DateFilter instance with format
      return new FilterClass(dateConfig);
    }

    if (FilterClass === NumericFilter) {
      // TypeScript understands that config is NumericConfig here
      const numericConfig = config as NumericConfig;
      if (!numericConfig.options) {
        throw new Error("NumericFilter requires options.");
      }
      return new FilterClass(numericConfig);
    }
    throw new Error("Unsupported filter type.");
  }
}
