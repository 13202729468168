/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@esgian/esgianui";
import * as turf from "@turf/turf";
import { Feature, Polygon as PolygonType } from "@turf/turf";
import mapboxgl, { Map as MapType, MapMouseEvent, Marker } from "mapbox-gl";

import { Circle } from "../../helper/CircleLayer";
import {
  filterListByDatePicker,
  filterListByDropdown,
  filterListByNumericSlider,
} from "../../helper/fllter";
import {
  AddFilterButton,
  AddInfoButton,
  CENTER,
  CIRCLE_CLICK_ZOOM,
  CreateMap,
  removeMarkers,
  searchZoom,
  THRESHOLD_ZOOM,
  UpdateFilterButton,
  ZOOM,
} from "../../helper/map";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCompany,
  getSearchObject,
  getThemeMode,
  getUserLocationCoordinates,
} from "../../store/selector/common";
import {
  getInfoModal,
  getLeaseRegionLookup,
  getLeaseRoundListInfo,
  getMapActiveLegends,
  getPolygons,
  getProjectListInfoListInfo,
  getSelectedFilter,
} from "../../store/selector/windLeasingAndProject";
import {
  setFilterModal,
  setSelectedCountryName,
  setSelectedLeaseAreaId,
  setSelectedProjectId,
} from "../../store/slice/common";
import {
  fetchPolygons,
  fetchRegionCoordinates,
  setInfoModal,
} from "../../store/slice/windLeasingAndProject";
import {
  CompanyOption,
  CoordinatesObj,
  FeatureTypeEnum,
  LeaseRoundInfo,
  MapFeature,
  MapLayer,
  MapSource,
  ProjectInfo,
} from "../../types";
import { InfoModal } from "..";

import { Country } from "./helper/CountryLayer";
import { Polygon } from "./helper/PolygonLayer";
import {
  debounce,
  handleMousemove,
  Popup,
  resetMapState,
  useStyles,
} from "./helper";

import "mapbox-gl/dist/mapbox-gl.css";

const { REACT_APP_MAP_TOKEN } = process.env;

type Prop = {
  controller?: boolean;
  zoom?: number;
  center?: [number, number] | CoordinatesObj;
  move?: boolean;
  markerInfo?: {
    name: string;
    center: [number, number] | CoordinatesObj;
  };
};

export const WindLeasingAndProjectMap: FC<Prop> = ({
  controller = true,
  zoom = ZOOM,
  center = CENTER,
  move = true,
  markerInfo,
}) => {
  const polygon = new Polygon();
  const circle = new Circle();
  const country = new Country();

  const currentViewRef = useRef<Feature<PolygonType, []> | null>(null);
  const mapInstanceRef = useRef<MapType | null>(null);
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const [markerList, setMarkerList] = useState<Marker[]>([]);
  const [filterCount, setFilterCount] = useState(0);
  const [isMapReady, setIsMapReady] = useState(false);
  const [isPolygonReady, setIsPolygonReady] = useState(false);

  const classes = useStyles(themeMode);
  const company = useSelector(getCompany);
  const polygonsData = useSelector(getPolygons);
  const isInfoModalOpen = useSelector(getInfoModal);
  const selectedFilter = useSelector(getSelectedFilter);
  const leaseRegionLookup = useSelector(getLeaseRegionLookup);
  const mapActiveLegends = useSelector(getMapActiveLegends);
  const userLocationCoordinates = useSelector(getUserLocationCoordinates);
  const searchObject = useSelector(getSearchObject);
  const projectList = useSelector(getProjectListInfoListInfo);
  const leaseRoundList = useSelector(getLeaseRoundListInfo);

  useEffect(() => {
    if (selectedFilter) {
      const appliedFilterCount = Object.values(selectedFilter).filter(
        (arr) => Array.isArray(arr) && arr.length > 0,
      ).length;
      setFilterCount(appliedFilterCount);
    } else {
      setFilterCount(0);
    }
  }, [selectedFilter]);

  const [filterVersion, setFilterVersion] = useState(0);

  const filteredPolygons = useMemo(() => {
    if (!polygonsData || !polygonsData.length) return [];
    if (!projectList || !projectList.length) return polygonsData;
    if (!leaseRoundList || !leaseRoundList.length) return polygonsData;

    let list = polygonsData;
    let listProjects = projectList;
    let listLeaseRound = leaseRoundList;

    // Apply selected filters if available
    if (
      selectedFilter &&
      listProjects.length &&
      (selectedFilter.projectStatus?.length > 0 ||
        selectedFilter.projectType?.length > 0 ||
        selectedFilter.developers?.length > 0 ||
        selectedFilter.operationDate?.length > 0)
    ) {
      listProjects = filterListByDropdown(
        listProjects,
        selectedFilter,
      ) as ProjectInfo[];
      listProjects = filterListByDatePicker(
        listProjects,
        selectedFilter,
      ) as ProjectInfo[];
      listProjects = filterListByNumericSlider(
        listProjects,
        selectedFilter,
      ) as ProjectInfo[];
      list = list.filter((polygonItem) =>
        listProjects.some(
          (project) => project.id === polygonItem.windProjectId,
        ),
      );
    }

    if (
      selectedFilter &&
      listLeaseRound.length &&
      (selectedFilter.leaseRoundStatus?.length > 0 ||
        selectedFilter.leaseRoundType?.length > 0 ||
        selectedFilter.awardDate?.length > 0)
    ) {
      listLeaseRound = filterListByDropdown(
        listLeaseRound,
        selectedFilter,
      ) as LeaseRoundInfo[];
      listLeaseRound = filterListByDatePicker(
        listLeaseRound,
        selectedFilter,
      ) as LeaseRoundInfo[];
      listLeaseRound = filterListByNumericSlider(
        listLeaseRound,
        selectedFilter,
      ) as LeaseRoundInfo[];
      list = list.filter((polygonItem) =>
        listLeaseRound.some(
          (leaseRound) => leaseRound.name === polygonItem.name,
        ),
      );
    }
    if (
      selectedFilter &&
      listLeaseRound.length &&
      selectedFilter.name?.length > 0
    ) {
      list = filterListByDropdown(list, selectedFilter) as MapFeature[];
    }

    return list;
  }, [polygonsData, selectedFilter, projectList, leaseRoundList]);

  const handlePolygonClick = (id: number, regionTypeId: number) => {
    if (regionTypeId === FeatureTypeEnum.LeaseArea) {
      dispatch(setSelectedLeaseAreaId(id));
    }
    if (regionTypeId === FeatureTypeEnum.Project) {
      dispatch(setSelectedProjectId(id));
    }
  };

  const getCurrenViewPolygon = () => {
    if (!mapInstanceRef.current) return [];

    const bounds = mapInstanceRef.current.getBounds();
    const sw = bounds?.getSouthWest();
    const ne = bounds?.getNorthEast();
    if (ne && sw) {
      const currentViewPolygon = [
        [sw.lng, ne.lat],
        [ne.lng, ne.lat],
        [ne.lng, sw.lat],
        [sw.lng, sw.lat],
        [sw.lng, ne.lat],
      ];

      return currentViewPolygon;
    }
    return null;
  };

  const fetchPolygonData = debounce(async (currenFilterVersion?: number) => {
    if (!mapInstanceRef.current) return;
    const coordinates = getCurrenViewPolygon();
    if (!coordinates) return;
    const currentViewPolygon: Feature<PolygonType, []> = turf.polygon([
      coordinates,
    ]);

    if (currentViewRef.current && currenFilterVersion === filterVersion) {
      const isContained = turf.booleanContains(
        currentViewRef.current,
        currentViewPolygon,
      );
      if (isContained) return;
    }

    const viewPolygon = `POLYGON ((${coordinates
      .map((coord) => `${coord[0]} ${coord[1]}`)
      .join(", ")}))`;

    await dispatch(fetchPolygons(viewPolygon));
    if (currenFilterVersion) {
      setFilterVersion(currenFilterVersion);
    }
    const currentZoom = mapInstanceRef.current?.getZoom();
    if (currentZoom >= 5) {
      currentViewRef.current = currentViewPolygon;
    }
  }, 500);

  const handleMoveEnd = debounce(async () => {
    if (!mapInstanceRef.current) return;
    const currentZoom = mapInstanceRef.current?.getZoom();
    if (currentZoom < THRESHOLD_ZOOM) return;
    fetchPolygonData();
  }, 100);

  const handleClick = (e: MapMouseEvent) => {
    if (!mapInstanceRef.current) return;
    const firstFeature: mapboxgl.MapboxGeoJSONFeature =
      mapInstanceRef.current.queryRenderedFeatures(e.point, {
        layers: [
          MapLayer.Country,
          MapLayer.Circle,
          MapLayer.Polygon,
          MapLayer.Point,
        ],
      })[0];
    if (
      firstFeature &&
      firstFeature.source === MapSource.CircleSource &&
      firstFeature.geometry.type === "Point"
    ) {
      const coordinates = firstFeature.geometry.coordinates;
      mapInstanceRef.current.flyTo({
        center: coordinates as [number, number],
        zoom: CIRCLE_CLICK_ZOOM,
      });
    }

    if (firstFeature && firstFeature.source === MapSource.CountrySource) {
      mapInstanceRef.current.setFeatureState(firstFeature, { clicked: true });
      dispatch(
        setSelectedCountryName(firstFeature?.properties?.name_en as string),
      );
    }

    if (
      firstFeature &&
      [MapSource.Polygon, MapSource.Point].includes(
        firstFeature.source as MapSource,
      )
    ) {
      if (firstFeature?.properties?.regionTypeId === 3) {
        dispatch(setSelectedLeaseAreaId(firstFeature?.properties?.id));
      } else if (
        [MapLayer.Polygon, MapLayer.Point].includes(
          firstFeature?.layer?.id as MapLayer,
        )
      ) {
        handlePolygonClick(
          firstFeature?.properties?.id,
          FeatureTypeEnum.Project,
        );
      }
      mapInstanceRef.current.setFeatureState(firstFeature, { clicked: true });
    }
  };

  const mapMoves = debounce(() => {
    if (!mapInstanceRef.current) return;
    const coordinates = getCurrenViewPolygon();
    if (!coordinates) return;
    const currentViewPolygon: Feature<PolygonType, []> = turf.polygon([
      coordinates,
    ]);
    if (currentViewRef.current) {
      const isContained = turf.booleanContains(
        currentViewRef.current,
        currentViewPolygon,
      );
      if (!isContained) {
        const currentZoom = mapInstanceRef.current?.getZoom();
        if (currentZoom >= THRESHOLD_ZOOM) {
          fetchPolygonData();
        }
      } else {
        circle.disable(mapInstanceRef.current);
        polygon.visible(mapInstanceRef.current);
      }
    }
  }, 100);

  const handleZoom = () => {
    if (!mapInstanceRef.current) return;
    const currentZoom = mapInstanceRef.current?.getZoom();
    const circleSource = mapInstanceRef.current.getSource(
      MapSource.CircleSource,
    );
    if (currentZoom < THRESHOLD_ZOOM) {
      Popup?.remove();
      if (!circleSource) {
        circle.update(mapInstanceRef.current, leaseRegionLookup || []);
      } else {
        circle.visible(mapInstanceRef.current);
      }
    } else {
      circle.disable(mapInstanceRef.current);
    }
  };

  useEffect(() => {
    mapboxgl.accessToken = REACT_APP_MAP_TOKEN ?? "";
    const map = CreateMap(zoom, center, move, themeMode);
    map.on("style.load", function () {
      mapInstanceRef.current = map;
      setIsMapReady(true);
      map.on("zoom", handleZoom);
      map.on("click", handleClick);
      map.on("mouseleave", () => resetMapState(map));
      map.on("moveend", handleMoveEnd);
      map?.on("move", mapMoves);

      country.init(map, themeMode);
      polygon.init(map, themeMode);
      circle.init(map);
    });

    if (controller) {
      AddFilterButton(map, classes.filterButton, filterCount, () =>
        dispatch(setFilterModal(true)),
      );
      AddInfoButton(map, classes.filterButton, () =>
        dispatch(setInfoModal(true)),
      );
      map.addControl(new mapboxgl.NavigationControl(), "top-right");
    }

    if (zoom > THRESHOLD_ZOOM) {
      fetchPolygonData();
    }

    if (move) {
      map.on("mousemove", (e) => handleMousemove(e, map, handlePolygonClick));
    }
    return () => {
      if (map) {
        polygon.remove(map);
        circle.remove(map);
        map.remove();
        mapInstanceRef.current = null;
      }
    };
  }, [themeMode]);

  useEffect(() => {
    if (
      isMapReady &&
      isPolygonReady &&
      filteredPolygons &&
      mapInstanceRef.current
    ) {
      const currentZoom = mapInstanceRef.current?.getZoom();
      if (currentZoom >= THRESHOLD_ZOOM) {
        polygon.update(mapInstanceRef.current, filteredPolygons);
        circle.disable(mapInstanceRef.current);
      } else {
        circle.visible(mapInstanceRef.current);
      }
    }
  }, [filteredPolygons, mapInstanceRef, isMapReady, isPolygonReady]);

  useEffect(() => {
    const polygonsReady =
      Array.isArray(filteredPolygons) && filteredPolygons.length > 0;
    setIsPolygonReady(polygonsReady);
  }, [filteredPolygons]);

  useEffect(() => {
    if (mapInstanceRef.current) {
      if (leaseRegionLookup?.length) {
        const filteredLeaseRegionLookup = leaseRegionLookup.filter((region) =>
          filteredPolygons.some(
            (filteredPolygon) => filteredPolygon.regionId === region.regionId,
          ),
        );
        circle.update(mapInstanceRef.current, filteredLeaseRegionLookup);
      }
    }
  }, [leaseRegionLookup, filteredPolygons]);

  useEffect(() => {
    if (mapInstanceRef.current) {
      UpdateFilterButton(mapInstanceRef.current, filterCount);
      UpdateFilterButton(mapInstanceRef.current, filterCount);
    }
  }, [filterCount]);

  useEffect(() => {
    if (isMapReady) {
      let currenFIlterVersion = filterVersion;
      fetchPolygonData(++currenFIlterVersion);
      dispatch(fetchRegionCoordinates());
    }
  }, [isMapReady, mapActiveLegends]);

  useEffect(() => {
    if (mapInstanceRef.current && searchObject && move) {
      if (searchObject.type === "Company") {
        removeMarkers(markerList, (v) => setMarkerList(v));
        const selectedCompanyWindProjects = company.find(
          (com: CompanyOption) => com.company.id === searchObject.id,
        )?.windProjects;
        const markers: Marker[] = [];
        selectedCompanyWindProjects?.forEach((selectedProject) => {
          if (
            selectedProject?.coordinates?.lat &&
            selectedProject?.coordinates?.lon
          ) {
            const marker = new mapboxgl.Marker({ color: "red" })
              .setLngLat(selectedProject.coordinates)
              .addTo(mapInstanceRef.current as MapType);

            const popupContent = document.createElement("div");
            popupContent.innerHTML = selectedProject?.name;
            popupContent.className = "popup-content";
            popupContent.onclick = () => {
              dispatch(setSelectedProjectId(selectedProject.windProjectId));
            };

            const popup = new mapboxgl.Popup({
              offset: 35,
              className: "marker-popup",
            }).setDOMContent(popupContent);

            marker.setPopup(popup).togglePopup();
            markers.push(marker);
          }
        });
        mapInstanceRef.current.flyTo({
          zoom: 2,
        });
        setMarkerList(markers);
      } else {
        if (searchObject.centroidCoordinates) {
          const marker = new mapboxgl.Marker({ color: "red" })
            .setLngLat(searchObject?.centroidCoordinates)
            .addTo(mapInstanceRef.current as MapType);

          const popupContent = document.createElement("div");
          popupContent.innerHTML = searchObject?.title || "";
          popupContent.className = "popup-content";

          const popup = new mapboxgl.Popup({
            offset: 35,
            className: "marker-popup",
          }).setDOMContent(popupContent);

          marker.setPopup(popup).togglePopup();
          setMarkerList([marker]);
          searchZoom(mapInstanceRef.current, searchObject);
        }
      }
    }
    if (!searchObject) {
      removeMarkers(markerList, (v) => setMarkerList(v));
    }
  }, [mapInstanceRef, searchObject, company]);

  useEffect(() => {
    if (mapInstanceRef.current && userLocationCoordinates) {
      mapInstanceRef.current.flyTo({
        center: userLocationCoordinates,
        zoom: 3,
      });
    }
  }, [userLocationCoordinates, mapInstanceRef]);

  useEffect(() => {
    if (
      isMapReady &&
      isPolygonReady &&
      markerInfo &&
      mapInstanceRef.current &&
      filteredPolygons
    ) {
      const marker = new mapboxgl.Marker({ color: "red" })
        .setLngLat(markerInfo?.center)
        .addTo(mapInstanceRef.current);

      const popup = new mapboxgl.Popup({
        offset: 35,
        className: "marker-popup",
      }).setText(markerInfo?.name);

      marker.setPopup(popup).togglePopup();
    }
  }, [filteredPolygons, markerInfo, isMapReady, isPolygonReady]);

  useEffect(() => {
    fetchPolygonData();
    dispatch(fetchRegionCoordinates());
  }, [mapActiveLegends]);

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        id="map"
        sx={{
          ...classes?.map,
        }}
      />
      <InfoModal
        open={isInfoModalOpen}
        handleClose={() => dispatch(setInfoModal(false))}
      />
    </Box>
  );
};
