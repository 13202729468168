import { RootState } from "../../types";

export const getTags = (state: RootState) => {
  return state.newsFeedCountries.tags;
};

export const getProductId = (state: RootState) => {
  return state.newsFeedCountries.productId;
};

export const getIsLoading = (state: RootState) => {
  return state.newsFeedCountries.isLoading;
};

export const getAuthorList = (state: RootState) => {
  return state.newsFeedCountries.authorList;
};

export const getNewsFeed = (state: RootState) => {
  return state.newsFeedCountries.newsFeedCountries;
};

export const getNewsFeedSelectedItem = (state: RootState) => {
  return state.newsFeedCountries.selectedItem;
};

export const getTotalCount = (state: RootState) => {
  return state.newsFeedCountries.totalCount;
};

export const getSelectedLabelIds = (state: RootState) => {
  return state.newsFeedCountries.selectedLabelIds;
};

export const getSearch = (state: RootState) => {
  return state.newsFeedCountries.search;
};

export const getPage = (state: RootState) => {
  return state.newsFeedCountries.page;
};
