import { configureStore } from "@reduxjs/toolkit";
import { common } from "./slice/common";
import { windLeasingAndProject } from "./slice/windLeasingAndProject";
import { leaseRoundTimeline } from "./slice/leaseRoundTimeline";
import { factory } from "./slice/factory";
import { turbineModels } from "./slice/turbineModels";
import { factoryContract } from "./slice/factoryContract";
import { floatingTechnologies } from "./slice/floatingTechnology";
import { installation } from "./slice/installation";
import { cableLay } from "./slice/cableLay";
import { target } from "./slice/target";
import { contract } from "./slice/contract";
import { supplyDemand } from "./slice/supplyDemand";
import { vesselAvailability } from "./slice/vesselAvailability";
import { analytic } from "./slice/analytics";
import { newsFeed } from "./slice/newsFeed";
import { activityVisualisation } from "./slice/activityVisualisation";
import { powerOutput } from "./slice/powerOutput";
import { ppaContract } from "./slice/ppaContract";
import { newsFeedCountries } from "./slice/newsFeedCountries";

export const store = configureStore({
  reducer: {
    common,
    factory,
    windLeasingAndProject,
    leaseRoundTimeline,
    turbineModels,
    contract,
    factoryContract,
    floatingTechnologies,
    installation,
    cableLay,
    target,
    supplyDemand,
    vesselAvailability,
    analytic,
    newsFeed,
    newsFeedCountries,
    activityVisualisation,
    powerOutput,
    ppaContract,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
