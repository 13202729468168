import { useEffect, useMemo, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@esgian/esgianui";

import { Loading } from "../../components/Loading";
import { NewsFeedBox } from "../../components/NewsFeedBox";
import { NewsFeedHeader } from "../../components/NewsFeedHeader";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getIsLoading,
  getNewsFeed,
  getPage,
  getProductId,
  getSearch,
  getSelectedLabelIds,
  getTags,
  getTotalCount,
} from "../../store/selector/newsFeed";
import {
  fetchAuthorList,
  fetchNewsFeed,
  fetchProduct,
  fetchTagList,
  setPage,
} from "../../store/slice/newsFeed";
import { GenericType, ROUTES_CONFIG } from "../../types";

export const NewsFeed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const savedPosition = sessionStorage.getItem("scrollPosition");
  const scrollBoxRef = useRef<GenericType>(null);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const page = useSelector(getPage);
  const productId = useSelector(getProductId);
  const allTags = useSelector(getTags);
  const newsFeed = useSelector(getNewsFeed);
  const loading = useSelector(getIsLoading);
  const selectedLabelIds = useSelector(getSelectedLabelIds);
  const search = useSelector(getSearch);
  const totalCount = useSelector(getTotalCount);
  const filteredTags = useMemo(
    () => (allTags ? allTags.filter((tag) => tag.id !== 34) : []),
    [allTags],
  );

  useEffect(() => {
    if (inView && newsFeed?.length) {
      dispatch(setPage(page + 1));
    }
  }, [inView, newsFeed, dispatch, page]);

  useEffect(() => {
    dispatch(fetchProduct());
    dispatch(fetchTagList());
    dispatch(fetchAuthorList());
  }, [dispatch]);

  useEffect(() => {
    if (productId && !savedPosition && filteredTags?.length) {
      dispatch(fetchNewsFeed(page));
    }
  }, [productId, page, filteredTags, dispatch, savedPosition]);

  useEffect(() => {
    if (productId && !savedPosition) {
      dispatch(setPage(1));
      dispatch(fetchNewsFeed(1));
    }
  }, [selectedLabelIds, search, dispatch, productId, savedPosition]);

  useEffect(() => {
    if (savedPosition && scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = parseInt(savedPosition, 10);
      sessionStorage.removeItem("scrollPosition");
    }
  }, [savedPosition]);

  const handleButtonClick = (id: number) => {
    const scrollPosition = scrollBoxRef?.current?.scrollTop;
    sessionStorage.setItem("scrollPosition", scrollPosition);
    navigate(`${ROUTES_CONFIG.NewsFeedItem.baseUrl}/${id}`);
  };

  return (
    <Box sx={{ px: 2 }}>
      <NewsFeedHeader
        labelList={filteredTags}
        count={totalCount}
        loading={loading}
        isMainPage={true}
      />
      <Box
        ref={scrollBoxRef}
        sx={{ height: "calc(100vh - 190px)", overflow: "auto" }}
      >
        {newsFeed?.map((item, index) => (
          <NewsFeedBox
            key={index}
            id={item.id}
            imageURL={item.contentImages?.[0]?.path}
            title={item.title}
            subTitle={item.preface}
            author={item.authors}
            labelList={item.tags}
            date={item.created}
            content={item.content}
            handleButtonClick={() => handleButtonClick(item.id)}
          />
        ))}
        {!loading && totalCount > page * 20 && (
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress size={20} />
            <Box ref={ref} />
          </Box>
        )}
        {loading && <Loading />}
      </Box>
    </Box>
  );
};
