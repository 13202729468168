import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { api } from "../../api/api";
import { safelyCallApi } from "../../helper/requestWrapper";
import { getCountries } from "../../store/selector/common";
import { GenericType, WindProject } from "../../types";

export const useLookupQueries = () => {
  const queryClient = useQueryClient(); // Access queryClient instance
  const countries = useSelector(getCountries);
  const lookupProjectsQuery = useQuery({
    queryKey: ["lookupProjects"],
    placeholderData: [],
    queryFn: () => {
      return safelyCallApi(api.lookup.getProjects())
        .then(({ status, data }) => {
          if (status !== 200) {
            return [];
          }
          return data;
        })
        .catch(() => []);
    },
  });

  const lookupProjectsDetailsQuery = useQuery({
    queryKey: ["lookupProjectDetails"],
    placeholderData: [],
    queryFn: () => {
      const requestBody = {};
      return safelyCallApi(api.project.getProjectListInfo(requestBody))
        .then(({ status, data }) => {
          if (status !== 200) {
            return [];
          }
          return data;
        })
        .catch(() => []);
    },
  });

  const lookupProjectsObject = useMemo(() => {
    if (lookupProjectsQuery.isFetching) return null;
    const res: GenericType = {};
    lookupProjectsQuery.data.forEach((project: WindProject) => {
      res[project.windProjectId] = { ...project };
    });
    return res;
  }, [lookupProjectsQuery.data, lookupProjectsQuery.isFetching]);

  const lookupCountriesObject = useMemo(() => {
    if (!countries.length) return null;
    const res: GenericType = {};

    countries.forEach((country) => {
      res[country.id as number] = country;
    });
    return res;
  }, [countries]);

  const detailedProjectObject = useMemo(() => {
    if (lookupProjectsDetailsQuery.isFetching) return null;
    const res: GenericType = {};
    lookupProjectsDetailsQuery.data.forEach((project: GenericType) => {
      res[project.windProject.id] = { ...project };
    });
    return res;
  }, [lookupProjectsDetailsQuery]);

  const resetLookupData = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ["lookupProjects"] });
  }, [queryClient]);

  return {
    lookupProjectsQuery,
    lookupCountriesObject,
    lookupProjectsObject,
    detailedProjectObject,
    resetLookupData,
  };
};
