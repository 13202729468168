/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@esgian/esgianui";

import {
  BidDrawer,
  CountryDrawer,
  LeaseAreaDrawer,
  LeaseRoundDrawer,
  ProjectDrawer,
  WindLeasingAndProjectMap,
} from "../../components";
import { Loading } from "../../components/Loading";
import { FilterModal } from "../../components/Modal/FilterModal";
import { WindLeasingAndProjectList } from "../../components/WindLeasingAndProjectList";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getFilterModal,
  getIsLookupLoading,
  getProjects,
  getSelectedBidId,
  getSelectedLeaseAreaId,
  getSelectedLeaseRoundIs,
  getSelectedProjectId,
  getSideMenu,
  getThemeMode,
  getViewTab,
} from "../../store/selector/common";
import {
  getFilterOptionsCountryLease,
  getFilterOptionsMap,
  getFilterOptionsProject,
  getListViewSelectedTab,
  getSelectedFilter,
} from "../../store/selector/windLeasingAndProject";
import {
  fetchLookup,
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedBidId,
  setSelectedCountryName,
  setSelectedLeaseAreaId,
  setSelectedLeaseRoundId,
  setSelectedProjectId,
  setViewTab,
} from "../../store/slice/common";
import {
  fetchCountryInfo,
  fetchLeaseRegionInfo,
  fetchLeaseRoundInfo,
  fetchProjectInfo,
  fetchRegionNames,
  setSelectedFilter,
} from "../../store/slice/windLeasingAndProject";
import { ROUTES_CONFIG, ViewTabEnum } from "../../types";

export const WindLeasingAndProject: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sideMenu = useSelector(getSideMenu);
  const viewTab = useSelector(getViewTab);
  const isFilterModalOpen = useSelector(getFilterModal);
  const selectedLeaseAreaId = useSelector(getSelectedLeaseAreaId);
  const selectedProjectId = useSelector(getSelectedProjectId);
  const selectedLeaseRoundId = useSelector(getSelectedLeaseRoundIs);
  const selectedBidId = useSelector(getSelectedBidId);
  const selectedFilter = useSelector(getSelectedFilter);
  const optionsMap = useSelector(getFilterOptionsMap);
  const optionsProjectList = useSelector(getFilterOptionsProject);
  const optionsCountryLeaseList = useSelector(getFilterOptionsCountryLease);
  const isLookupLoading = useSelector(getIsLookupLoading);
  const projectStatus = useSelector(getProjects);
  const themeMode = useSelector(getThemeMode);

  const tab = useSelector(getListViewSelectedTab);
  const [loading, setLoading] = useState<boolean>(true);

  let options;

  if (viewTab === "List" && tab === "project") {
    options = optionsProjectList;
  } else if (viewTab === "Map") {
    options = optionsMap;
  } else if (
    viewTab === "List" &&
    (tab === "country" || tab === "lease-round" || tab === "lease-area")
  ) {
    options = optionsCountryLeaseList;
  }

  useEffect(() => {
    dispatch(setSelectedFilter({})); // Reset filters on tab change
  }, [tab, dispatch]);

  useEffect(() => {
    dispatch(
      fetchLookup([
        "landArea",
        "leaseArea",
        "seaArea",
        "leaseRoundStatus",
        "leaseRoundType",
        "projectStatus",
        "projectType",
        "leaseRound",
        "projects",
        "company",
      ]),
    );
    dispatch(fetchCountryInfo({}));
    dispatch(fetchLeaseRegionInfo({}));
    dispatch(fetchLeaseRoundInfo({}));
    dispatch(fetchProjectInfo());
    dispatch(fetchRegionNames());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setViewTab(ViewTabEnum.Map));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, [sideMenu, themeMode]);

  const onLeaseRoundClick = (leaseRoundId: number) => {
    navigate(`${ROUTES_CONFIG.LeaseRoundTimeLineId.baseUrl}/${leaseRoundId}`);
  };

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading && !isLookupLoading && projectStatus.length ? (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      {viewTab === ViewTabEnum.Map ? (
        <WindLeasingAndProjectMap />
      ) : (
        <WindLeasingAndProjectList />
      )}
      <CountryDrawer
        onSelectLeaseArea={(id) => dispatch(setSelectedLeaseAreaId(id))}
        onClose={() => dispatch(setSelectedCountryName(null))}
        onLeaseRoundClick={onLeaseRoundClick}
      />
      <LeaseAreaDrawer
        id={selectedLeaseAreaId}
        onClose={() => dispatch(setSelectedLeaseAreaId(null))}
        onBidClick={(id) => dispatch(setSelectedBidId(id))}
        onLeaseClick={(id) => dispatch(setSelectedLeaseAreaId(id))}
        onLeaseRoundClick={onLeaseRoundClick}
      />
      <BidDrawer
        id={selectedBidId}
        onClose={() => dispatch(setSelectedBidId(null))}
      />
      <ProjectDrawer
        id={selectedProjectId}
        onClose={() => dispatch(setSelectedProjectId(null))}
        onLeaseRoundClick={onLeaseRoundClick}
      />
      <LeaseRoundDrawer
        id={selectedLeaseRoundId}
        onClose={() => dispatch(setSelectedLeaseRoundId(null))}
        onLeaseRoundClick={onLeaseRoundClick}
      />
      {options ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          onChange={(filterValues) => {
            dispatch(setSelectedFilter(filterValues));
          }}
          selectedFilter={selectedFilter}
          options={options}
          {...(viewTab === "List" && tab === "project"
            ? { groupsOption: options }
            : {})}
        />
      ) : null}
    </Box>
  ) : (
    <Loading />
  );
};
