import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import {
  DROPDOWN_LABELS,
  DropdownConfig,
  isDropdownConfig,
  isNumericConfig,
  NUMERIC_LABELS,
  NumericConfig,
} from "../../types/filters";

export const getFloatingTechnologyLoading = (state: RootState) => {
  return state.floatingTechnologies.isLoading;
};

export const getFloatingTechnologyList = (state: RootState) => {
  return state.floatingTechnologies.floatingTechnologyList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.floatingTechnologies.selectedFilter;
};

export const getFloatingTechnologyChartData = (state: RootState) => {
  return state.floatingTechnologies.chartData;
};

export const getSortingInfo = (state: RootState) => {
  return state.floatingTechnologies.sortingInfo;
};

export const getFilterOptions = (state: RootState) => {
  if (!state.floatingTechnologies?.floatingTechnologyList) return null;

  const filters = {
    technology: {
      inputLabelText: "Technology",
      options: sortValues([
        ...new Set(
          state.floatingTechnologies.floatingTechnologyList
            .map((item) => item.technology)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    company: {
      inputLabelText: "Company",
      options: sortValues(
        [
          ...new Set(
            state.floatingTechnologies.floatingTechnologyList.flatMap(
              (item) => item.company?.split(", ") || [],
            ),
          ),
        ].filter(
          (value) => value !== null && value !== "" && value !== undefined,
        ),
      ).map((value: string) => ({ id: value, value })),
    },
    floaterType: {
      inputLabelText: "Floater type",
      options: sortValues([
        ...new Set(
          state.floatingTechnologies.floatingTechnologyList
            .map((item) => item.floaterType)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    floaterMaterial: {
      inputLabelText: "Floater material",
      options: sortValues(
        [
          ...new Set(
            state.floatingTechnologies.floatingTechnologyList.flatMap(
              (item) => item.floaterMaterial?.split(", ") || [],
            ),
          ),
        ].filter(
          (value) => value !== null && value !== "" && value !== undefined,
        ),
      ).map((value: string) => ({ id: value, value })),
    },
  };
  if (Object.values(filters).some((filter) => !filter.options?.length)) {
    return null;
  }

  const result: GenericType = {};

  Object.entries(filters).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });

  return result;
};
