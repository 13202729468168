import { FilterItemType, GenericType } from "../types";

export abstract class FilterConfig {
  type: FilterItemType;

  inputLabelText: string;

  labelKey: string;

  options?:
    | { id: string | number; value: string }[]
    | [number, number]
    | { id: string | number; value: string; type: string }[];

  format?: string;

  groupBy?: string;

  constructor(config: {
    type: FilterItemType;
    inputLabelText: string;
    labelKey: string;
    options?:
      | { id: string | number; value: string }[]
      | { id: string | number; value: string; type: string }[];
    format?: string;
    groupBy?: string;
  }) {
    this.type = config.type;
    this.inputLabelText = config.inputLabelText;
    this.labelKey = config.labelKey;
    this.options = config.options;
    this.format = config.format;
    this.groupBy = config.groupBy;
  }

  abstract getConfig(): GenericType;
}
