import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  CloseIcon,
  SearchIcon,
  TextField,
  Typography,
} from "@esgian/esgianui";

import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  getSearch as getNewsFeedSearch,
  getSelectedLabelIds as getNewsFeedLabelIds,
} from "../../store/selector/newsFeed";
import {
  getSearch as getNewsFeedCountriesSearch,
  getSelectedLabelIds as getNewsFeedCountriesLabelIds,
} from "../../store/selector/newsFeedCountries";
import {
  setLabelId as setNewsFeedLabelId,
  setSearch as setNewsFeedSearch,
} from "../../store/slice/newsFeed";
import {
  setLabelId as setNewsFeedCountriesLabelId,
  setSearch as setNewsFeedCountriesSearch,
} from "../../store/slice/newsFeedCountries";
import { ThemeModeEnum } from "../../types";
import { NewsFeedLabels } from "../../types/newsFeed";

type Prop = {
  labelList: NewsFeedLabels[] | null;
  count: number;
  loading: boolean;
  isMainPage: boolean;
};

export const NewsFeedHeader: FC<Prop> = ({
  labelList,
  count,
  loading,
  isMainPage,
}) => {
  const dispatch = useDispatch();
  const selectedLabelIds = useSelector(
    isMainPage ? getNewsFeedLabelIds : getNewsFeedCountriesLabelIds,
  );
  const themeMode = useSelector(getThemeMode);
  const savedPosition = sessionStorage.getItem("scrollPosition");
  const search = useSelector(
    isMainPage ? getNewsFeedSearch : getNewsFeedCountriesSearch,
  );

  const [isExpanded, setIsExpanded] = useState(true);
  const [searchValue, setSearchValue] = useState(search || "");
  const displayedLabels = isExpanded ? labelList : labelList?.slice(0, 5);

  useEffect(() => {
    if (!savedPosition) {
      const handler = setTimeout(() => {
        dispatch(
          isMainPage
            ? setNewsFeedSearch(searchValue)
            : setNewsFeedCountriesSearch(searchValue),
        );
      }, 300);
      return () => {
        clearTimeout(handler);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: 0.5 }}>
        <TextField
          id="outlined-multiline-flexible"
          label="Search for topics, locations, company names "
          sx={{
            width: 1,
            height: 40,
            "& .MuiInputBase-root": { pr: 1 },
          }}
          multiline={false}
          size="small"
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          value={searchValue}
          onChange={handleSearchChange}
        />
      </Box>
      <Box
        sx={{ my: 1, display: "flex", flexWrap: "wrap", alignItems: "center" }}
      >
        {displayedLabels?.map((item, i) => (
          <Typography
            key={i}
            sx={{
              background: selectedLabelIds?.includes(item.id)
                ? themeMode === ThemeModeEnum.Dark
                  ? "#434343"
                  : "#CBCBCB"
                : themeMode === ThemeModeEnum.Dark
                  ? "#1A1A1A"
                  : "#E0E0E0",
              padding: "4px 8px",
              borderRadius: 16,
              cursor: "pointer",
              fontSize: 14,
              ml: 1,
              mt: 1,
            }}
            onClick={() =>
              dispatch(
                isMainPage
                  ? setNewsFeedLabelId(item.id)
                  : setNewsFeedCountriesLabelId(item.id),
              )
            }
          >
            {item.tag}
            {selectedLabelIds?.includes(item.id) && (
              <CloseIcon
                sx={{
                  top: 0,
                  right: 0,
                  padding: "3px",
                }}
                color="inherit"
                fontSize="small"
              />
            )}
          </Typography>
        ))}
        {labelList && labelList.length > 5 && (
          <Button
            variant="text"
            sx={{
              ml: 1,
              mt: 1,
              height: 32,
              color: themeMode === ThemeModeEnum.Dark ? "#CBCBCB" : "#434343",
            }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </Button>
        )}
      </Box>
      <Typography variant="body2" sx={{ fontSize: 14, mb: 1 }}>
        {!loading ? `Displaying ${count} results` : null}
      </Typography>
    </Box>
  );
};
