import { GenericType } from "./index";

export type DropdownConfig = {
  inputLabelText:
    | "Project"
    | "Company"
    | "Project Status"
    | "Country"
    | "Factory"
    | "Factory Functions"
    | "Equipment Type"
    | "Factory Status"
    | "Wind project"
    | "Wind project status"
    | "PPA type"
    | "Buyer"
    | "Seller"
    | "Supplier"
    | "Wind farm"
    | "Contract status"
    | "Turbine model"
    | "Foundation type"
    | "Cable type"
    | "Output current"
    | "Substation"
    | "Components"
    | "Technology type"
    | "Floating technology"
    | "Vessel name"
    | "Vessel owner"
    | "Project Type"
    | "Contracted equipment type"
    | "Owner"
    | "Design"
    | "Vessel type"
    | "Status"
    | "Vessel capability"
    | "Class"
    | "IMO"
    | "Flag"
    | "Constructed yard"
    | "MMSI"
    | "Crane type"
    | "Crane model"
    | "Crane make"
    | "Newbuild"
    | "Jacking system make"
    | "Jacking system model"
    | "Future crane upgrade"
    | "Name"
    | "Developers"
    | "Sea area"
    | "Turbine supplier"
    | "Location"
    | "Lease round status"
    | "Suppliers"
    | "Generator system"
    | "Technology"
    | "Floater type"
    | "Floater material"
    | "Lease area type"
    | "Project status"
    | "Project type"
    | "Lease round"
    | "Sea region"
    | "Foundation supplier"
    | "Main port name"
    | "Turbine installer"
    | "Cable installer"
    | "Foundation installer"
    | "Floating material"
    | "Hydrogen"
    | "Hydrogen project"
    | "Hydrogen developer"
    | "Hydrogen technology"
    | "Oil and gas"
    | "Oil and gas fields";
  options: { id: string | number; value: string }[];
  groupBy?: string;
};

export type DateConfig = {
  inputLabelText:
    | "Date"
    | "Contract award date"
    | "Start date"
    | "End date"
    | "Date of delivery"
    | "Operation period"
    | "Award period"
    | "Decommissioning date"
    | "Date online";
  format: string;
};

export type NumericConfig = {
  inputLabelText:
    | "Voltage kV"
    | "Max cable capacity"
    | "Length"
    | "Width"
    | "Draught"
    | "Max deck strength"
    | "Deck area"
    | "Max speed"
    | "Max pob"
    | "Primary turntable capacity"
    | "Primary outer diameter"
    | "Primary core diameter"
    | "Primary core height"
    | "Secondary turntable capacity"
    | "Secondary outer diameter"
    | "Secondary core height"
    | "Max laying velocity"
    | "Bollard pull"
    | "Crane max capacity"
    | "Max lift above deck"
    | "Max deck load capacity"
    | "Min water depth"
    | "Max water depth"
    | "Pre load capacity"
    | "Leg length"
    | "Elevating jacking speed"
    | "Max jacking load"
    | "Hook height above deck"
    | "Long boom weight"
    | "Long boom radius"
    | "Short boom weight"
    | "Short boom radius"
    | "Boom length"
    | "Current installed capacity (MW)"
    | "Turbine power (MW)"
    | "Turbine nominal power"
    | "Rotor diameter"
    | "Swept area"
    | "Number of blades"
    | "Cut in speed"
    | "Cut out speed"
    | "Rated wind speed"
    | "Capacity"
    | "Number of turbines"
    | "Maximum water depth";

  options: [number, number];
};

export type FilterConfig = DropdownConfig | DateConfig | NumericConfig;

export const DROPDOWN_LABELS = [
  "Project",
  "Company",
  "Project Status",
  "Country",
  "Factory",
  "Factory Functions",
  "Equipment Type",
  "Factory Status",
  "Wind project",
  "Wind project status",
  "PPA type",
  "Buyer",
  "Seller",
  "Supplier",
  "Wind farm",
  "Contract status",
  "Turbine model",
  "Foundation type",
  "Cable type",
  "Output current",
  "Substation",
  "Components",
  "Technology type",
  "Floating technology",
  "Vessel name",
  "Vessel owner",
  "Project Type",
  "Contracted equipment type",
  "Owner",
  "Design",
  "Vessel type",
  "Status",
  "Vessel capability",
  "Class",
  "IMO",
  "Flag",
  "Constructed yard",
  "MMSI",
  "Crane type",
  "Crane model",
  "Crane make",
  "Newbuild",
  "Jacking system make",
  "Jacking system model",
  "Future crane upgrade",
  "Name",
  "Developers",
  "Sea area",
  "Turbine supplier",
  "Location",
  "Lease round status",
  "Suppliers",
  "Generator system",
  "Technology",
  "Floater type",
  "Floater material",
  "Lease area type",
  "Project status",
  "Project type",
  "Lease round",
  "Sea region",
  "Foundation supplier",
  "Main port name",
  "Turbine installer",
  "Cable installer",
  "Foundation installer",
  "Floating material",
  "Hydrogen",
  "Hydrogen project",
  "Hydrogen developer",
  "Hydrogen technology",
  "Oil and gas",
  "Oil and gas fields",
];

export const DATE_LABELS = [
  "Date",
  "Contract award date",
  "Start date",
  "End date",
  "Date of delivery",
  "Operation period",
  "Award period",
  "Decommissioning date",
  "Date online",
];

export const NUMERIC_LABELS = [
  "Voltage kV",
  "Max cable capacity",
  "Length",
  "Width",
  "Draught",
  "Max deck strength",
  "Deck area",
  "Max speed",
  "Max pob",
  "Primary turntable capacity",
  "Primary outer diameter",
  "Primary core diameter",
  "Primary core height",
  "Secondary turntable capacity",
  "Secondary outer diameter",
  "Secondary core height",
  "Max laying velocity",
  "Bollard pull",
  "Crane max capacity",
  "Max lift above deck",
  "Max deck load capacity",
  "Min water depth",
  "Max water depth",
  "Pre load capacity",
  "Leg length",
  "Elevating jacking speed",
  "Max jacking load",
  "Hook height above deck",
  "Long boom weight",
  "Long boom radius",
  "Short boom weight",
  "Short boom radius",
  "Boom length",
  "Current installed capacity (MW)",
  "Turbine power (MW)",
  "Turbine nominal power",
  "Rotor diameter",
  "Swept area",
  "Number of blades",
  "Cut in speed",
  "Cut out speed",
  "Rated wind speed",
  "Capacity",
  "Number of turbines",
  "Maximum water depth",
];

export const isDropdownConfig = (
  options: GenericType[],
): options is { id: string | number; value: string }[] => {
  return (
    Array.isArray(options) &&
    options.every(
      (option) =>
        typeof option.id === "string" ||
        (typeof option.id === "number" && typeof option.value === "string"),
    )
  );
};

export const isDropdownConfigWithType = (
  options: GenericType[],
): options is { id: string | number; value: string; type: string }[] => {
  return (
    Array.isArray(options) &&
    options.every(
      (option) =>
        typeof option.id === "string" ||
        (typeof option.id === "number" &&
          typeof option.value === "string" &&
          typeof option.type === "string"),
    )
  );
};

export const isNumericConfig = (
  options: number[],
): options is [number, number] => {
  return (
    Array.isArray(options) &&
    options.length === 2 &&
    typeof options[0] === "number" &&
    typeof options[1] === "number"
  );
};

export const normalizeDateKey = (key: string): string => {
  const mapping: Record<string, string> = {
    contractAwardDate: "Contract award date",
    date: "Date",
    startDate: "Start date",
    endDate: "End date",
    dateOfDelivery: "Date of delivery",
    operationDate: "Operation period",
    awardDate: "Award period",
    decommissioningDate: "Decommissioning date",
    dateOnline: "Date online",
  };
  return mapping[key] || key;
};

export const normalizeNumericKey = (key: string): string => {
  const mapping: Record<string, string> = {
    voltageKV: "Voltage kV",
    maxCableCapacity: "Max cable capacity",
    length: "Length",
    width: "Width",
    draught: "Draught",
    maxDeckStrength: "Max deck strength",
    deckArea: "Deck area",
    maxSpeed: "Max speed",
    maxPob: "Max pob",
    primaryTurntableCapacity: "Primary turntable capacity",
    primaryOuterDiameter: "Primary outer diameter",
    primaryCoreDiameter: "Primary core diameter",
    primaryCoreHeight: "Primary core height",
    secondaryTurntableCapacity: "Secondary turntable capacity",
    secondaryOuterDiameter: "Secondary outer diameter",
    secondaryCoreHeight: "Secondary core height",
    maxLayingVelocity: "Max laying velocity",
    bollardPull: "Bollard pull",
    craneMaxCapacity: "Crane max capacity",
    maxLiftAboveDeck: "Max lift above deck",
    maxDeckLoadCapacity: "Max deck load capacity",
    minWaterDepth: "Min water depth",
    maxWaterDepth: "Max water depth",
    preLoadCapacity: "Pre load capacity",
    legLength: "Leg length",
    elevatingJackingSpeed: "Elevating jacking speed",
    maxJackingLoad: "Max jacking load",
    hookHeightAboveDeck: "Hook height above deck",
    longBoomWeight: "Long boom weight",
    longBoomRadius: "Long boom radius",
    shortBoomWeight: "Short boom weight",
    shortBoomRadius: "Short boom radius",
    boomLength: "Boom length",
    installedCapacity: "Current installed capacity (MW)",
    turbinePowers: "Turbine power (MW)",
    turbineNominalPower: "Turbine nominal power",
    rotorDiameter: "Rotor diameter",
    sweptArea: "Swept area",
    numberOfBlades: "Number of blades",
    cutInSpeed: "Cut in speed",
    cutOutSpeed: "Cut out speed",
    ratedWindSpeed: "Rated wind speed",
    capacity: "Capacity",
    numberOfTurbines: "Number of turbines",
    maximumWaterDepth: "Maximum water depth",
  };
  return mapping[key] || key;
};
