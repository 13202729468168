import moment from "moment";

import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import {
  DROPDOWN_LABELS,
  DropdownConfig,
  isDropdownConfig,
  isNumericConfig,
  NUMERIC_LABELS,
  NumericConfig,
} from "../../types/filters";

export const getSortingInfo = (state: RootState) => {
  return state.powerOutput.tableSortOrder;
};
export const getPowerOutputWindProject = (state: RootState) => {
  return state.powerOutput.selectedWindProject;
};
export const getPowerOutputCompareWindProjects = (state: RootState) => {
  return state.powerOutput.compareWindProjects;
};
export const getPowerOutputPeriodType = (state: RootState) => {
  return state.powerOutput.periodType;
};
export const getSelectedFilter = (state: RootState) => {
  return state.powerOutput.selectedFilter;
};
export const getPowerProjectMain = (state: RootState) => {
  return state.powerOutput.mainPageList;
};

export const getPowerOutputPeriodStartDate = (state: RootState) => {
  if (state.powerOutput.periodType?.id === 1) {
    if (state.powerOutput.timePeriod?.name === "YTD") {
      return moment().startOf("year");
    }
    if (state.powerOutput.timePeriod?.name === "Max") {
      return moment().subtract(20, "year");
    }
    const { number = 20, unit = "year" } = state.powerOutput.timePeriod || {};

    return moment().subtract(number, unit);
  }
  return moment().subtract(20, "year");
};
export const getPowerOutputTimePeriod = (state: RootState) => {
  return state.powerOutput.timePeriod;
};

export const getPowerOutputDisabledTime = (state: RootState) => {
  return state.powerOutput.disabledOption;
};

export const getFilterOptions = (state: RootState) => {
  const filters = {
    name: {
      inputLabelText: "Name",
      options: sortValues([
        ...new Set(
          Object.values(state.powerOutput.mainPageList ?? {})
            .map((item) => item.projectName)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    installedCapacity: {
      inputLabelText: "Current installed capacity (MW)",
      options: [
        0,
        Math.max(
          ...Object.values(state.powerOutput.mainPageList ?? {})
            .map((item) => item.installedCapacity * 1000)
            .filter((value) => value !== null && value !== undefined),
        ),
      ],
    },
    country: {
      inputLabelText: "Country",
      options: sortValues([
        ...new Set(
          Object.values(state.powerOutput.mainPageList ?? {})
            .map((item) => item.countryName)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    developers: {
      inputLabelText: "Developers",
      options: sortValues([
        ...new Set(
          Object.values(state.powerOutput.mainPageList ?? {})
            .map((item) => item.developers)
            .filter(
              (value) => value !== null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) =>
              value
                .replace(" includes ", "")
                .replace(" and ", "")
                .split(",")
                .map((v) => v.trim()),
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    seaArea: {
      inputLabelText: "Sea area",
      options: sortValues([
        ...new Set(
          Object.values(state.powerOutput.mainPageList ?? {})
            .map((item) => item.seaArea)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            ),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    turbineSuppliers: {
      inputLabelText: "Turbine supplier",
      options: sortValues([
        ...new Set(
          Object.values(state.powerOutput.mainPageList ?? {})
            .map((item) => item.turbineSuppliers)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    turbineModels: {
      inputLabelText: "Turbine model",
      options: sortValues([
        ...new Set(
          Object.values(state.powerOutput.mainPageList ?? {})
            .map((item) => item.turbineModels)
            .filter(
              (value) => value != null && value !== "" && value !== undefined,
            )
            .flatMap((value: string) => value.split(", ")),
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    turbinePowers: {
      inputLabelText: "Turbine power (MW)",
      options: [
        0,
        Math.max(
          ...Object.values(state.powerOutput.mainPageList ?? {})
            .map((item) =>
              item.turbinePowers.split(", ").map((val) => Number(val.trim())),
            )
            .flat()
            .filter(
              (value) => value !== null && value !== undefined && !isNaN(value),
            ),
        ),
      ],
    },
  };

  if (Object.values(filters).some((filter) => !filter.options?.length)) {
    return null;
  }

  const result: GenericType = {};
  Object.entries(filters).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;
      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: options.map((option) => ({
            id: option.id,
            value: option.value,
          })),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number],
        });
      }
    }
  });

  return result;
};
