import { FC, useEffect, useState } from "react";
import { ApexChart, Box, getEsgianTheme } from "@esgian/esgianui";

import { useSelector } from "../../hooks/use-selector";
import { getThemeMode, getUser } from "../../store/selector/common";
import { GenericType, THEME, ThemeModeEnum } from "../../types";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    chart: {
      width: "99.9%",
      "& .apexcharts-menu": {
        background:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        "& .apexcharts-menu-item": {
          ":hover": {
            color: theme.palette.common.black,
          },
        },
      },
    },
  };
};

type Props = {
  series: GenericType[];
  categories: string[];
  exportName?: string;
};

export const AnalyticsChart: FC<Props> = ({
  series,
  categories,
  exportName,
}) => {
  const themeMode = useSelector(getThemeMode);
  const user = useSelector(getUser);
  const classes = useStyles(themeMode);
  const theme = getEsgianTheme(themeMode, THEME);
  const [chartHeight, setChartHeight] = useState("400px");

  useEffect(() => {
    const updateHeight = () => {
      const height = `${window.innerHeight - 270}px`;
      setChartHeight(height);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: hasExportRight,
        },
        export: {
          theme: themeMode,
          png: {
            filename: exportName,
          },
          csv: {
            filename: exportName,
          },
          svg: {
            filename: exportName,
          },
          autoSelected: "zoom",
        },
      },
      zoom: {
        enabled: false,
      },
      background:
        themeMode === ThemeModeEnum.Dark && theme.palette.common.black,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.white
              : theme.palette.common.black,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.white
              : theme.palette.common.black,
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      labels: {
        colors:
          themeMode === ThemeModeEnum.Light
            ? theme.palette.common.black
            : theme.palette.common.white,
      },
    },
    tooltip: {
      enabled: true,
      theme: themeMode === ThemeModeEnum.Dark ? "dark" : "light",
    },
  };

  return (
    <Box sx={classes.chart}>
      <ApexChart
        height={chartHeight}
        options={options}
        type="bar"
        data={series}
        loading={!series}
      />
    </Box>
  );
};
