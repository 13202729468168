import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@esgian/esgianui";
import moment from "moment";

import {
  BidDrawer,
  LeaseRoundDrawer,
  LeaseRoundTimeLine,
  LeaseWithMapDrawer,
} from "../../components";
import { LeaseRoundHeader } from "../../components/LeaseRoundHeader";
import { Loading } from "../../components/Loading";
import { FilterModal } from "../../components/Modal/FilterModal";
import { exportToCSV } from "../../helper/exportCsv";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCountries,
  getFilterModal,
  getSelectedBidId,
  getSelectedLeaseAreaId,
  getSelectedLeaseRoundIs,
} from "../../store/selector/common";
import {
  getFilterOptions,
  getLeaseRoundIsLoading,
  getLeaseRoundTimelineResource,
  getSelectedFilter,
} from "../../store/selector/leaseRoundTimeline";
import {
  fetchLookup,
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedBidId,
  setSelectedLeaseAreaId,
  setSelectedLeaseRoundId,
} from "../../store/slice/common";
import {
  fetchLeaseRoundTimelineEvents,
  setSelectedFilter,
} from "../../store/slice/leaseRoundTimeline";
import { fetchLeaseRoundInfo } from "../../store/slice/windLeasingAndProject";
import {
  IdNamePair,
  Resource,
  ROUTES_CONFIG,
  TimelineCsvRow,
} from "../../types";

import "../../mobiscrollTheme.scss";

export const LeasingRound: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const isFilterModalOpen = useSelector(getFilterModal);
  const selectedLeaseAreaId = useSelector(getSelectedLeaseAreaId);
  const selectedLeaseRoundIs = useSelector(getSelectedLeaseRoundIs);
  const selectedBidId = useSelector(getSelectedBidId);
  const resource = useSelector(getLeaseRoundTimelineResource);
  const selectedFilter = useSelector(getSelectedFilter);
  const isLoading = useSelector(getLeaseRoundIsLoading);
  const countries = useSelector(getCountries);
  const options = useSelector(getFilterOptions);

  const [viewType, setViewType] = useState<"year" | "month">("year");
  const [collapsedAll, setCollapsedAll] = useState(false);

  const extensibleResource = useMemo(() => {
    return JSON.parse(JSON.stringify(resource));
  }, [resource]);

  useEffect(() => {
    dispatch(fetchLookup(["landArea", "seaArea", "leaseRoundStatus"]));
  }, [dispatch]);

  useEffect(() => {
    if (!countries.length) return;
    if (!selectedFilter) return;
    const filter = {
      countryIds: [],
      leaseRoundStatusIds: [],
    };
    dispatch(fetchLeaseRoundTimelineEvents({ filter, id }));
    dispatch(fetchLeaseRoundInfo({}));
  }, [dispatch, selectedFilter, countries, id]);

  const exportCsv = () => {
    const rows: Partial<TimelineCsvRow>[] = [];
    resource?.forEach((country: Resource) => {
      let item: Partial<TimelineCsvRow> = { countryName: country.name };
      country?.children?.forEach((round: Resource) => {
        item = {
          ...item,
          leaseRoundName: round.name,
          leaseRoundOpenDate: round.openDate,
          leaseRoundCloseDate: round.closeDate,
          leaseRoundAwardDate: round.awardDate,
        };
        if (!round?.children?.length) {
          rows.push(item);
          return;
        }
        round?.children?.forEach((region: Resource) => {
          item = {
            ...item,
            leaseAreaName: region.name,
          };
          if (!region?.children?.length) {
            rows.push(item);
            return;
          }
          region?.children?.forEach((bid: Resource) => {
            item = {
              ...item,
              bidName: bid.name,
              submittedDate: bid.submittedDate,
              withdrawalDate: bid.withdrawalDate,
              unsuccessfulDate: bid.unsuccessfulDate,
              successfulDate: bid.successfulDate,
              status: bid.status,
              companyNames: bid.companies
                .map((c: IdNamePair) => c.name)
                .join("| "),
            };
            rows.push(item);
          });
        });
      });
    });
    const formattedRows = rows.map((row) => ({
      ...row,
      leaseRoundOpenDate: row.leaseRoundOpenDate
        ? moment(row.leaseRoundOpenDate).format("YYYY-MM-DD")
        : "",
      leaseRoundCloseDate: row.leaseRoundCloseDate
        ? moment(row.leaseRoundCloseDate).format("YYYY-MM-DD")
        : "",
      leaseRoundAwardDate: row.leaseRoundAwardDate
        ? moment(row.leaseRoundAwardDate).format("YYYY-MM-DD")
        : "",
      submittedDate: row.submittedDate
        ? moment(row.submittedDate).format("YYYY-MM-DD")
        : "",
      withdrawalDate: row.withdrawalDate
        ? moment(row.withdrawalDate).format("YYYY-MM-DD")
        : "",
      unsuccessfulDate: row.unsuccessfulDate
        ? moment(row.unsuccessfulDate).format("YYYY-MM-DD")
        : "",
      successfulDate: row.successfulDate
        ? moment(row.successfulDate).format("YYYY-MM-DD")
        : "",
    }));
    const mapping = {
      countryName: "Country Name",
      leaseRoundName: "Lease Round Name",
      leaseRoundOpenDate: "Lease Round Open Date",
      leaseRoundCloseDate: "Lease Round Close Date",
      leaseRoundAwardDate: "Lease Round Award Date",
      leaseAreaName: "lease Area Name",
      bidName: "Bid Name",
      status: "Bid Status",
      submittedDate: "Submitted Date",
      withdrawalDate: "WithdrawalDate",
      unsuccessfulDate: "Unsuccessful Date",
      successfulDate: "Successful Date",
      companyNames: "Company Names",
    };
    exportToCSV(formattedRows, "timeline.csv", mapping);
  };

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLeaseRoundClick = (leaseRoundId: number) => {
    navigate(`${ROUTES_CONFIG.LeaseRoundTimeLineId.baseUrl}/${leaseRoundId}`);
  };

  return (
    <Box>
      <LeaseWithMapDrawer
        id={selectedLeaseAreaId}
        type="area"
        onClose={() => dispatch(setSelectedLeaseAreaId(null))}
      />
      <LeaseRoundDrawer
        id={selectedLeaseRoundIs}
        onClose={() => dispatch(setSelectedLeaseRoundId(null))}
        onLeaseRoundClick={onLeaseRoundClick}
      />
      <BidDrawer
        id={selectedBidId}
        onClose={() => dispatch(setSelectedBidId(null))}
      />
      <LeaseRoundHeader
        setViewType={setViewType}
        setCollapsedAll={setCollapsedAll}
        collapsedAll={collapsedAll}
        viewType={viewType}
        exportCsv={exportCsv}
        disableFilter={!!id}
      />
      {options ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          onChange={(filterValues) => {
            dispatch(setSelectedFilter(filterValues));
          }}
          selectedFilter={selectedFilter}
          options={options}
        />
      ) : null}
      {!isLoading ? (
        extensibleResource?.length ? (
          <LeaseRoundTimeLine
            setCollapsedAll={setCollapsedAll}
            viewType={viewType}
            resources={extensibleResource}
            collapsedAll={collapsedAll}
          />
        ) : null
      ) : (
        <Loading />
      )}
    </Box>
  );
};
