import Cookies from "js-cookie";

export const getAuthToken = () => {
  let token = null;
  if (process.env.REACT_APP_ENV === "LOCAL") {
    token = Cookies.get("esgian_auth_dev");
  }
  if (process.env.REACT_APP_ENV === "QA") {
    // token = Cookies.get("esgian_auth_test.stage"); use again after we switch to qa2 servers
    token = Cookies.get("esgian_auth_test2");
  }
  if (process.env.REACT_APP_ENV === "PROD") {
    token = Cookies.get("esgian_auth_prod");
  }
  return token;
};
