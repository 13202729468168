import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../api/api";
import { GenericType } from "../../types";
import { Analytic } from "../../types/analytics";

type State = {
  isLoading: boolean;
  analyticList: Analytic[] | null;
  selectedFilter: GenericType;
};

const initialState: State = {
  isLoading: false,
  analyticList: null,
  selectedFilter: {},
};

export const fetchAnalytics = createAsyncThunk(
  "analytics",
  async (_, { getState }) => {
    try {
      const state = getState() as GenericType;
      const selectedFilter = state.analytic.selectedFilter;
      const { data } = await api.analytics.getAnalytics({
        companyIds: selectedFilter.company?.map((p: GenericType) => p.id) ?? [],
        windProjectIds:
          selectedFilter.projects?.map((p: GenericType) => p.id) ?? [],
        projectStatusIds:
          selectedFilter.projectStatus?.map((p: GenericType) => p.id) ?? [],
        projectTypeIds:
          selectedFilter.projectType?.map((p: GenericType) => p.id) ?? [],
        startDate: selectedFilter?.date
          ? moment(selectedFilter?.date[0], "YYYY MM")
          : undefined,
        endDate: selectedFilter?.date?.[1]
          ? moment(selectedFilter?.date[1], "YYYY MM")
          : undefined,
      });
      const list = data
        .filter((item: Analytic) => item.year && item.capacity)
        .map((item: Analytic) => ({
          ...item,
          capacity: Math.round(item.capacity),
        }));
      return list;
    } catch (err) {
      return [];
    }
  },
);

const analyticSlice = createSlice({
  name: "analytic",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAnalytics.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchAnalytics.rejected, (state) => {
        state.isLoading = false;
        state.analyticList = null;
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.analyticList = action.payload;
      });
  },
});

export const { setSelectedFilter } = analyticSlice.actions;
export const analytic = analyticSlice.reducer;
